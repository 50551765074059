import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiError, useAPI, useUtils } from "../hooks";
import { faEllipsis, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { MutationData, ProblemCommentType } from "../types";
import { ProblemCommentsKey } from "../pages/query";

type ProblemCommentProps = {
    comment: ProblemCommentType;
    problemId: number;
    counterRef: React.RefObject<HTMLSpanElement>;
};

function ProblemComment({ comment, problemId, counterRef }: ProblemCommentProps) {
    const { dateAgo } = useUtils();
    const api = useAPI();
    const queryClient = useQueryClient();

    const removeComment = useMutation<MutationData & { commentCounter: number }, ApiError>({
        mutationFn: () => {
            return api.problem.removeComment(comment.id);
        },
        onSuccess: (data) => {
            queryClient.setQueryData<ProblemCommentType[]>(ProblemCommentsKey(problemId), (oldData) => (oldData ? oldData.filter((c) => c.id !== comment.id) : []));

            if (counterRef.current) {
                counterRef.current.textContent = data.commentCounter.toString();
            }
        },
        onError: (data) => {
            toast.error(data.message, { toastId: "remove-comment" });
        },
    });

    return (
        <div className="mt-2">
            <p className="d-inline-block">{comment.username}</p>
            <span className="ms-2" style={{ fontSize: 10 }}>
                {dateAgo(comment.createdAt)}
            </span>
            <div className="dropdown float-end">
                <span className="p-2" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsis} />
                </span>
                <ul className="dropdown-menu">
                    {comment.canRemove && (
                        <li>
                            <button className="dropdown-item text-danger" onClick={() => removeComment.mutate()}>
                                <FontAwesomeIcon icon={faTrash} /> Supprimer
                            </button>
                        </li>
                    )}
                </ul>
            </div>
            <p className="p-1 rounded-1 bg-lightgrey">{comment.content}</p>
        </div>
    );
}

export default ProblemComment;
