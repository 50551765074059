enum EnigmaTypeEnum {
    algebriques = "Problèmes algébriques",
    algorithmiques = "Problèmes algorithmiques",
    denombrement = "Problèmes de dénombrement",
    optimisation = "Problèmes d'optimisation",
    geometrie = "Problèmes de géométrie",
    logique = "Problèmes de logique",
}

export default EnigmaTypeEnum;
