import { SubscribeEmails } from "../../components";
import { useDocumentTitle } from "../../hooks";

function ProfilParameters() {
  useDocumentTitle("Profile - M@ths en-vie");

  return <SubscribeEmails />;
}

export default ProfilParameters;
