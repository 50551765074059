import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../hooks";

function HelpPhoto() {
    useDocumentTitle("Aide relative aux fonctionnalités de la banque de photos mathématiques");

    return (
        <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
            <h2>Banque de photos</h2>
            <p>Cette banque de photos collaborative a vocation à fournir aux enseignants des photographies supports d'activités mathématiques et notamment en résolution de problèmes.</p>
            <p className="mt-3">Cette section comporte deux fonctionnalités :</p>
            <ul>
                <li>la possibilité de consulter la banque de photos avec un accès par filtres ;</li>
                <li>la possibilité de soumettre ses propres photos à la communauté.</li>
            </ul>
            <p>
                À noter qu'il est possible, à l'image d'un réseau social, d'aimer certaines photos (les plus populaires apparaissant en premier) et de commenter les photos (intérêt, utilisation,
                points de vigilance...).
            </p>
            <p className="mt-3">Selon votre profil, vous pourrez accéder aux contenus et fonctionnalités décrits ci-dessous.</p>

            <h2 className="mt-3">Sommaire</h2>
            <ul>
                <li className="mb-2">
                    <Link to={{ hash: "consult" }}>Consulter</Link>
                </li>
                <li>
                    <Link to={{ hash: "contribute" }}>Contribuer</Link>
                </li>
            </ul>

            <hr />

            <h3 id="consult" className="mt-3">
                Consulter
            </h3>
            <p>Pour accéder à cette fonctionnalité, utilisez le bouton en page d'accueil ou le menu.</p>
            <p>Par défaut, la liste des photos auxquelles vous avez accès est affichée.</p>
            <p className="mt-3">Un clic sur le bouton « filtre » vous permet d'ouvrir la fenêtre des filtres, puis :</p>
            <ul>
                <li>sélectionnez la ou les banques à consulter : personnelle, collaborative (selon votre profil) ;</li>
                <li>sélectionnez le ou les types de photos à afficher (si vous cochez plusieurs mots-clés, s'afficheront toutes les photos contenant au moins un de ces types) ;</li>
                <li>cliquez sur « Filtrer ».</li>
            </ul>
            <div className="d-block text-center">
                <img src="img/consultPhotoHelp.webp" alt="Filtres de consultation" width="70%" />
            </div>
            <p className="mt-3">
                La liste des photos s'actualise alors en fonction de vos critères, en commençant par les photos les plus « aimées » de la communauté. Vous pouvez naviguer à travers les pages si toutes
                les photos ne peuvent s'afficher sur une seule page (10 par page).
            </p>
            <p className="mt-3">Pour chaque photo, vous avez accès aux informations suivantes :</p>
            <ul>
                <li>Mots clés relatifs à la photo (si plusieurs mots-clés, cliquer sur le petit triangle afin de déplier la zone)</li>
                <li>Auteur (pseudo)</li>
                <li>Nombre de « J'aime »</li>
                <li>Nombre de commentaires (un clic sur l'icône affiche l'ensemble des commentaires du problème)</li>
            </ul>
            <p className="mt-3">Vous pouvez, selon votre profil :</p>
            <ul>
                <li>copier la photo (clic droit puis « copier l'image ») dans le cache pour le coller dans un document personnel ;</li>
                <li>ajouter une mention « J'aime » ;</li>
                <li>commenter la photo.</li>
            </ul>
            <p className="mt-3">Grâce au menu accessible via les trois points de suspension en haut à droite de chaque photo, vous pouvez :</p>
            <ul>
                <li>signaler le photo (en cas d'erreur notamment dans les mots-clés) ;</li>
            </ul>

            <hr />

            <h3 id="contribute" className="mt-3">
                Contribuer
            </h3>
            <p>Vous pouvez contribuer à la banque collaborative en soumettant une photo et en l'indexant grâce aux mots-clés.</p>

            <p>À noter que si vous avez contribué à au moins trois phtos dans la banque collaborative, vous aurez accès à l'intégralité de celle-ci.</p>

            <p className="mt-3">Pour accéder à cette fonctionnalité, utilisez le bouton en page d'accueil ou le menu.</p>
            <p className="mt-3">S'ouvre alors la fenêtre « Ajouter une photo » :</p>
            <ul>
                <li>parcourez vos dossiers et sélectionnez votre fichier image au format .webp .jpeg ou .webp ;</li>
                <li>sélectionnez tous les mots clés se rapportant aux éléments mathématiques présents sur la photo.</li>
            </ul>

            <p>À noter que l'administrateur se réserve le droit de modifier tous les champs de votre problème (titre, énoncé, niveau, type) avant validation.</p>

            <div className="d-block text-center">
                <img src="img/contributePhotoHelp.webp" alt="Formulaire de contribution" width="50%" />
            </div>
        </div>
    );
}

export default HelpPhoto;
