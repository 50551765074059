/* eslint-disable jsx-a11y/img-redundant-alt */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { FormButtonLoading } from "..";
import { ProblemTypeEnum } from "../../Utils";
import { ModalPropsDefault, MutationData, ProblemType } from "../../types";
import { ProblemIdKey, ProblemsTypeKey } from "../../pages/query";
import { Alert, Modal } from "react-bootstrap";

type ProblemRemoveModalProps = {
  problem: ProblemType;
};

function ProblemRemoveModal({ show, closeModal, problem }: ModalPropsDefault & ProblemRemoveModalProps) {
  const api = useAPI();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setTimeout(() => {
      removeProblem.reset();
    }, 200);

    closeModal();
  };

  const removeProblem = useMutation<MutationData, ApiError>({
    mutationFn: () => {
      return api.problem.remove(problem.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ProblemsTypeKey(problem.discr));
      queryClient.invalidateQueries(ProblemIdKey(problem.id));
      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body>
        {removeProblem.isError && <Alert variant={removeProblem.error.variant}>{removeProblem.error.message}</Alert>}
        {removeProblem.isSuccess && <Alert variant="success">{removeProblem.data.message}</Alert>}
        <h5 className="mb-2">Êtez vous sûr de vouloir supprimer le problème suivant ?</h5>
        {problem.discr === ProblemTypeEnum.TextProblem && <p className="text-break">{problem.statement}</p>}
        {problem.discr === ProblemTypeEnum.Photo && (
          <div className="text-center">
            <img className="mw-100 h-auto" alt="Photo indisponible..." src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} />
          </div>
        )}
        {problem.discr === ProblemTypeEnum.PhotoProblem && (
          <>
            <div className="text-center">
              <img className="mw-100 h-auto" alt="Photo indisponible..." src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} />
            </div>
            <hr className="fw-bold" />
            <p className="text-break">{problem.statement}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <FormButtonLoading isLoading={removeProblem.isLoading} label="Annuler" onClick={handleClose} className="btn btn-ternary" showLoading={false} />
        <FormButtonLoading isLoading={removeProblem.isLoading} label="Supprimer" onClick={() => removeProblem.mutate()} className="btn btn-primary" />
      </Modal.Footer>
    </Modal>
  );
}

export default ProblemRemoveModal;
