/* eslint-disable jsx-a11y/img-redundant-alt */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../contexts/auth";
import { faCaretDown, faEllipsis, faExpand, faFlag, faMessage, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import LikeButton from "./LikeButton";
import { useUtils } from "../hooks";
import { ModalTypeEnum, PhotoTypeEnum } from "../Utils";
import { useRef } from "react";
import { useModals } from "../contexts/modals";
import { PhotoType } from "../types";

type PhotoProps = {
  problem: PhotoType;
};

function Photo({ problem }: Readonly<PhotoProps>) {
  const { user } = useAuth();
  const { photoTypeToArray } = useUtils();
  const types = photoTypeToArray(problem.type);

  const { openModal } = useModals();
  const counterRef = useRef<HTMLSpanElement>(null);

  return (
    <div>
      <div style={{ height: 24 }}>
        {user && (
          <div className="dropdown float-end">
            <span className="p-2" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faEllipsis} />
            </span>
            <ul className="dropdown-menu">
              {problem.canModify && (
                <li>
                  <button className="dropdown-item" onClick={() => openModal(ModalTypeEnum.ProblemModify, { problem: problem })}>
                    <FontAwesomeIcon icon={faPen} /> Modifier
                  </button>
                </li>
              )}
              {problem.canReport && (
                <li>
                  <button className="dropdown-item" onClick={() => openModal(ModalTypeEnum.ProblemReport, { problem: problem })}>
                    <FontAwesomeIcon icon={faFlag} /> Signaler
                  </button>
                </li>
              )}
              {user.isAdmin && (
                <li>
                  <button className="dropdown-item text-danger" onClick={() => openModal(ModalTypeEnum.ResizePhoto, { problem: problem })}>
                    <FontAwesomeIcon icon={faExpand} /> Redimensionner
                  </button>
                </li>
              )}
              {problem.canRemove && (
                <li>
                  <button className="dropdown-item text-danger" onClick={() => openModal(ModalTypeEnum.ProblemRemove, { problem: problem })}>
                    <FontAwesomeIcon icon={faTrash} /> Supprimer
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <div className="text-center">
        <div className="d-inline-block">
          <div className="d-table-cell align-middle" style={{ height: 300 }}>
            <img
              className="mw-100 mh-100 align-middle image-photo"
              src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment}
              alt="Photo indisponible..."
              onClick={() => openModal(ModalTypeEnum.Image, { attachment: problem.attachment })}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="text-center">
        <div className="d-flex flex-row flex-wrap justify-content-between">
          <div className="text-start mt-1 mb-1">
            <span className="badge p-1 text-bg-secondary text-black rounded-2 type-dropdown">
              {PhotoTypeEnum[types[0]]} {types.length > 1 && <FontAwesomeIcon className="ms-1" icon={faCaretDown} />}
            </span>
            {types.length > 1 && (
              <ul className="p-0 text-bg-secondary rounded-2 position-absolute z-1" style={{ marginTop: 1 }}>
                {types.map(
                  (element, index) =>
                    index > 0 && (
                      <span key={element} className="badge d-block text-start text-black">
                        {PhotoTypeEnum[element]}
                      </span>
                    )
                )}
              </ul>
            )}
          </div>
          <div className="mt-1 mb-1">
            <span className="badge text-bg-primary text-light">
              <span>Auteur : {problem.username}</span>
              {problem.grade !== 0 && <span className="badge bg-secondary text-black ms-1">{problem.grade}+</span>}
            </span>
          </div>
          <div className="text-end mt-1 mb-1">
            <button className="btn btn-outline-ternary btn-sm mx-1" onClick={() => openModal(ModalTypeEnum.ProblemComments, { problem: problem, counterRef: counterRef })}>
              <FontAwesomeIcon icon={faMessage} /> <span ref={counterRef}>{problem.counterComment}</span>
            </button>
            <LikeButton isLiked={problem.isLiked} nbrLikes={problem.counterLike} problemId={problem.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Photo;
