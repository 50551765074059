import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type CopyButtonProps = {
    title: string;
    statement: string;
};

function CopyButton({ title, statement }: CopyButtonProps) {
    const [copied, setCopied] = useState(false);

    const changeTitle = () => {
        navigator.clipboard.writeText(title + "\r\n\r\n" + statement);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <OverlayTrigger overlay={<Tooltip id="copy-button">{copied ? "Le problème a été copié." : "Copier le problème."}</Tooltip>}>
            <button className="btn btn-outline-primary btn-sm" onClick={changeTitle}>
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} /> Copier
            </button>
        </OverlayTrigger>
    );
}

export default CopyButton;
