import { generatePath, useNavigate, useParams } from "react-router-dom";
import * as Routes from "../../routes";
import { useEffect, useState } from "react";

type ParamsType = {
    page: string;
};

export default function ProtectedEnigma({ children }: React.PropsWithChildren) {
    const { page } = useParams<ParamsType>() as ParamsType;
    const navigate = useNavigate();

    const [canVisit, setCanVisit] = useState(false);

    useEffect(() => {
        if (!(page && Number.isInteger(Number.parseInt(page)) && Number.parseInt(page) > 0)) {
            navigate(generatePath(Routes.CONSULT_ENIGMA, { page: "1" }));
            return;
        }

        setCanVisit(true);
    }, [navigate, page]);

    return canVisit ? children : null;
}
