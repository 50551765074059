import { Link, To, useLocation } from "react-router-dom";

type LinkWithQueryProps = {
    to: To;
};

function LinkWithQuery({ children, to, ...props }: React.PropsWithChildren & LinkWithQueryProps & React.ButtonHTMLAttributes<HTMLAnchorElement>) {
    const { search } = useLocation();

    return (
        <Link to={to + search} {...props}>
            {children}
        </Link>
    );
}

export default LinkWithQuery;
