import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, useAPI, useDocumentTitle } from "../../hooks";
import { MutationData } from "../../types";
import { useState } from "react";
import { LinearProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { AdminGenerateAIData, AdminProblemsAiKey } from "./query";
import { GenerateAiTypeEnum, ResponseStateEnum } from "../../Utils";
import Select from "react-select";

const NBR_CONCURRENT_API_CALLS = 30;

type ResultsData = {
  id: number;
  state: number;
  responseState?: string;
  response?: string;
};

const options = [
  { value: GenerateAiTypeEnum.Response, label: "Réponse" },
  { value: GenerateAiTypeEnum.HelpSentence1, label: "Aide de niveau 1" },
  { value: GenerateAiTypeEnum.HelpSentence2, label: "Aide de niveau 2" },
  { value: GenerateAiTypeEnum.ResponseExplication, label: "Explication de la réponse" },
  { value: GenerateAiTypeEnum.ALL, label: "Tout" },
];

function AdminAi() {
  useDocumentTitle("Admin - M@ths en-vie");

  const queryClient = useQueryClient();
  const api = useAPI();

  const [type, setType] = useState(GenerateAiTypeEnum.Response);

  const { data, isFetching, isSuccess } = useQuery<number[]>(AdminProblemsAiKey(type), () => api.admin.problemsAI(type), {
    refetchOnReconnect: false,
  });

  const mutation = useMutation<MutationData & AdminGenerateAIData, ApiError, number>({
    mutationFn: (data) => {
      return api.admin.generateAI(data, type);
    },
  });

  const [value, setValue] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [results, setResults] = useState<ResultsData[]>([]);

  async function lunch() {
    if (isFetching || !isSuccess) {
      return;
    }

    setResults([]);
    const r: ResultsData[] = [];

    for (let i = 0; i < data.length; i += NBR_CONCURRENT_API_CALLS) {
      const promisesMutation = data.slice(i, i + NBR_CONCURRENT_API_CALLS).map(async (id) => {
        try {
          const res = await mutation.mutateAsync(id);
          setValue((old) => old + 1);

          r.push({
            id: id,
            state: 200,
            responseState: res.responseState,
            response: res.response,
          });

          return res;
        } catch (error) {
          setValue((old) => old + 1);
          if (error instanceof ApiError) {
            r.push({
              id: id,
              state: error.code,
            });
          }
          return null;
        }
      });

      try {
        setBuffer((old) => old + promisesMutation.length);
        await Promise.all(promisesMutation);
      } catch (error) {}
    }

    setValue(0);
    setBuffer(0);
    setResults(r);
    queryClient.invalidateQueries(AdminProblemsAiKey(type));
  }

  return (
    <div>
      <div className="d-flex justify-content-center column-gap-4">
        <button className="btn btn-primary" onClick={lunch} disabled={isFetching || !isSuccess || mutation.isLoading}>
          <FontAwesomeIcon icon={faCalculator} /> Générer les réponses <span className="badge text-bg-danger ms-3">{data?.length ?? 0}</span>
        </button>
        <div style={{ width: 250 }}>
          <Select
            value={options.filter((value) => value.value === type)}
            onChange={(value) => {
              if (value) setType(value.value);
            }}
            options={options}
          />
        </div>
      </div>
      {!isFetching && isSuccess && <LinearProgress color="success" className="my-3" value={(value / data.length) * 100} valueBuffer={(buffer / data.length) * 100} variant="buffer" />}
      {results.length !== 0 && (
        <div>
          <div>
            Réponses non trouvées :{" "}
            <span className="fw-bold">
              {
                results.filter((e) => {
                  return e.responseState === ResponseStateEnum.NoResponse || e.responseState === ResponseStateEnum.AnsweredByHuman;
                }).length
              }
            </span>
          </div>
          <div>
            Réponses trouvées par l'IA à faire valider par l'humain :{" "}
            <span className="fw-bold">
              {
                results.filter((e) => {
                  return e.responseState === ResponseStateEnum.AnsweredByIa;
                }).length
              }
            </span>
          </div>
          <div>
            Réponses en conflit :{" "}
            <span className="fw-bold">
              {
                results.filter((e) => {
                  return e.responseState === ResponseStateEnum.Conflict;
                }).length
              }
            </span>
          </div>
          <div>
            Réponses vérifiées par l'IA :{" "}
            <span className="fw-bold">
              {
                results.filter((e) => {
                  return e.responseState === ResponseStateEnum.Verified;
                }).length
              }
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminAi;
