import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI, useUtils } from "../../hooks";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ModalPropsDefault, MutationData, ProblemType } from "../../types";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { useEffect } from "react";

type ProblemReportModalProps = {
  problem: ProblemType;
};

type FormValues = {
  comment: string;
};

const defaultValues: FormValues = {
  comment: "",
};

function ProblemReportModal({ show, closeModal, saveData, savedData, problem }: ModalPropsDefault & ProblemReportModalProps) {
  const api = useAPI();
  const { transFromDiscr } = useUtils();

  const handleClose = () => {
    setTimeout(() => {
      reportProblem.reset();
    }, 200);
    saveData(getValues());
    closeModal();
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    reset(savedData);
  }, [reset, savedData]);

  const reportProblem = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.problem.report(problem.id, data);
    },
    onSuccess: () => {
      reset();
      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  const onSubmit = (data: FormValues) => {
    reportProblem.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h5>Signaler un{transFromDiscr(problem.discr, false, true)}</h5>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <h5 className="mb-2">Veuillez préciser la raison du signalement.</h5>
        {reportProblem.isError && <Alert variant={reportProblem.error.variant}>{reportProblem.error.message}</Alert>}
        {reportProblem.isSuccess && <Alert variant="success">{reportProblem.data.message}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3">
            <label htmlFor="comment-text" className="form-label">
              Commentaire
            </label>
            <textarea
              className="form-control"
              id="comment-text"
              {...register("comment", {
                required: { value: true, message: "Veuillez saisir votre commentaire." },
                maxLength: { value: 500, message: "Votre commentaire ne peut pas contenir plus de 500 caractères." },
              })}
              placeholder="Rédigez votre commentaire"
            ></textarea>
            <div className="form-text">500 caractères maximum</div>
            {errors.comment && <div className="invalid-feedback d-block">{errors.comment.message}</div>}
          </div>
          <button type="submit" id="submitFormModal" className="d-none"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Annuler
        </button>
        <label htmlFor="submitFormModal" className={"btn btn-primary" + (reportProblem.isLoading ? " disabled" : "")}>
          Signaler {reportProblem.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </label>
      </Modal.Footer>
    </Modal>
  );
}

export default ProblemReportModal;
