import { ProblemTypeEnum, SchoolZoneEnum } from "../Utils";
import { EnigmaFormValues, ProblemConsultFormValues } from "../types";

const StatsKey: [base: string] = ["stats"];

const RituelKey = (schoolZone: SchoolZoneEnum, classNivel: string[]): [base: string, schoolZone: SchoolZoneEnum, classNivel: string[]] => ["rituel", schoolZone, classNivel];

export type StatsData = {
  problemCount: number;
  userCount: number;
  numberOfVisit: number;
  topUsers: {
    nbProblem: number;
    username: string;
  }[];
};

const ProblemsKey: [base: string] = ["problems"];
const ProblemsCounterKey: [base: string, action: string] = [...ProblemsKey, "counter"];

export type ProblemCounterData = {
  [ProblemTypeEnum.TextProblem]: number;
  [ProblemTypeEnum.Photo]: number;
  [ProblemTypeEnum.PhotoProblem]: number;
  enigma: number;
};

const ProblemKey: [base: string] = ["problem"];
const ProblemIdKey = (problemId: number): [base: string, problemId: number] => [...ProblemKey, problemId];
const ProblemCommentsKey = (problemId: number): [base: string, problemId: number, action: string] => [...ProblemIdKey(problemId), "comments"];

const ProblemsTypeKey = (type: ProblemTypeEnum | "enigma"): [base: string, type: ProblemTypeEnum | "enigma"] => [...ProblemsKey, type];
const ProblemsTypeFiltersPageKey = (
  type: ProblemTypeEnum | "enigma",
  filters: ProblemConsultFormValues | EnigmaFormValues,
  page: number
): [base: string, type: ProblemTypeEnum | "enigma", filters: ProblemConsultFormValues | EnigmaFormValues, page: number] => [...ProblemsTypeKey(type), filters, page];

export { ProblemCommentsKey, ProblemIdKey, ProblemsTypeFiltersPageKey, ProblemsTypeKey, ProblemsKey, ProblemsCounterKey, RituelKey, StatsKey };
