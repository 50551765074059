import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI } from "../hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import { MutationData } from "../types";

type LikeButtonProps = {
    isLiked: boolean;
    nbrLikes: number;
    problemId: number;
};

function LikeButton({ isLiked, nbrLikes, problemId }: LikeButtonProps) {
    const api = useAPI();

    const [isLikedState, setIsLikedState] = useState(isLiked);
    const [nbrLikesState, setNbrLikesState] = useState(nbrLikes);

    const like = useMutation<MutationData & { isLiked: boolean; nbrLikes: number }, ApiError>({
        mutationFn: () => {
            return api.problem.like(problemId);
        },
        onSuccess: (data) => {
            setIsLikedState(data.isLiked);
            setNbrLikesState(data.nbrLikes);
        },
        onError: (data) => {
            toast.error(data.message, { toastId: "like-problem" });
        },
    });

    return (
        <button className={"btn btn-outline-ternary btn-sm me-1" + (isLikedState ? " text-secondary" : "")} onClick={() => like.mutate()} disabled={like.isLoading}>
            <FontAwesomeIcon icon={faThumbsUp} fontWeight="bold" /> <span>{nbrLikesState}</span>
        </button>
    );
}

export default LikeButton;
