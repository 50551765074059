import Carousel from "./Carousel";
import { Checkbox, CheckboxArray } from "./Checkbox";
import CopyButton from "./CopyButton";
import BaseLayout from "./BaseLayout";
import EditionTextProblemType from "./EditionTextProblemType";
import EditionPhotoProblemType from "./EditionPhotoProblemType";
import Enigma from "./Enigma";
import EnigmaConsultForm from "./EnigmaConsultForm";
import Footer from "./Footer";
import FormButtonLoading from "./FormButtonLoading";
import Header from "./Header";
import LayoutPage from "./LayoutPage";
import LikeButton from "./LikeButton";
import LinkWithQuery from "./LinkWithQuery";
import Loader from "./Loader";
import Pagination from "./Pagination";
import Photo from "./Photo";
import PhotoConsultForm from "./PhotoConsultForm";
import PhotoForm from "./PhotoForm";
import { PhotoPlaceholder, PhotoProblemPlaceholder, TextProblemPlaceholder } from "./ProblemPlaceholder";
import PhotoProblem from "./PhotoProblem";
import PhotoProblemConsultForm from "./PhotoProblemConsultForm";
import PhotoProblemForm from "./PhotoProblemForm";
import ProblemComment from "./ProblemComment";
import ResizePhotoCanvas from "./ResizePhotoCanvas";
import TextProblem from "./TextProblem";
import TextProblemConsultForm from "./TextProblemConsultForm";
import TextProblemForm from "./TextProblemForm";
import ScrollToAnchor from "./ScrollToAnchor";
import SubscribeEmails from "./UnsubscribeEmails";
import { lazy } from "react";

const Chart = lazy(() => import("./Chart"));

export {
  BaseLayout,
  Carousel,
  Chart,
  Checkbox,
  CheckboxArray,
  CopyButton,
  EditionTextProblemType,
  EditionPhotoProblemType,
  Enigma,
  EnigmaConsultForm,
  Footer,
  FormButtonLoading,
  Header,
  LayoutPage,
  LikeButton,
  LinkWithQuery,
  Loader,
  Pagination,
  Photo,
  PhotoConsultForm,
  PhotoForm,
  PhotoPlaceholder,
  PhotoProblem,
  PhotoProblemConsultForm,
  PhotoProblemForm,
  PhotoProblemPlaceholder,
  ProblemComment,
  ResizePhotoCanvas,
  TextProblem,
  TextProblemConsultForm,
  TextProblemForm,
  TextProblemPlaceholder,
  ScrollToAnchor,
  SubscribeEmails,
};
