import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Header, Footer } from ".";

const LayoutPage = () => {
    const headerHeight = 50;

    return (
        <>
            <Header height={headerHeight} />
            <div className="container-fluid" style={{ marginTop: headerHeight }}>
                <div style={{ padding: "20px 0px" }}>
                    <Outlet />
                </div>
            </div>
            <ToastContainer autoClose={5000} position="bottom-right" hideProgressBar draggable={false} limit={2} />
            <Footer />
        </>
    );
};

export default LayoutPage;
