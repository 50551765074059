import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProblemTypeEnum } from "../Utils";
import React, { Dispatch, SetStateAction } from "react";
import { EditionType } from "../types";

type EditionTextProblemTypeProps = {
  setEditionType: Dispatch<SetStateAction<EditionType | null>>;
};

function EditionTextProblemType({ setEditionType }: Readonly<EditionTextProblemTypeProps>) {
  const stylePdf: React.CSSProperties = { position: "absolute", top: "-5%", right: "-2%", color: "red" };
  const styleProjector: React.CSSProperties = { width: "17%", position: "absolute", top: "-5%", right: "-2.5%" };

  return (
    <div className="mx-auto text-center" style={{ maxWidth: 960 }}>
      <h1 className="mb-3 fw-semibold">Editez des fiches pour vos élèves</h1>
      <div className="p-3">
        <p className="lead mb-4">Chaque format peut contenir autant de problèmes que vous souhaitez.</p>
        <div className="row">
          <div className="col-3 position-relative">
            <FontAwesomeIcon icon={faFilePdf} size="2x" style={stylePdf} />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["pdf", 1] })}>
              <img src="img/textProblemPdfType1.webp" className="img-fluid" alt="Type 1 PDF" />
            </button>
            <span>Liste avec grande police d'écriture</span>
          </div>
          <div className="col-3 position-relative">
            <FontAwesomeIcon icon={faFilePdf} size="2x" style={stylePdf} />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["pdf", 2] })}>
              <img src="img/textProblemPdfType2.webp" className="img-fluid" alt="Type 2 PDF" />
            </button>
            <span>Liste avec petite police d'écriture</span>
          </div>
          <div className="col-3 position-relative">
            <FontAwesomeIcon icon={faFilePdf} size="2x" style={stylePdf} />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["pdf", 3] })}>
              <img src="img/textProblemPdfType3.webp" className="img-fluid" alt="Type 3 PDF" />
            </button>
            <span>2 problèmes par page (résolution sur la feuille)</span>
          </div>
          <div className="col-3 position-relative">
            <FontAwesomeIcon icon={faFilePdf} size="2x" style={stylePdf} />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["pdf", 4] })}>
              <img src="img/textProblemPdfType4.webp" className="img-fluid" alt="Type 4 PDF" />
            </button>
            <span>4 problèmes par page (résolution sur la feuille)</span>
          </div>
        </div>
      </div>

      <h1 className="mb-3 fw-semibold">Projetez des problèmes pour la classe</h1>
      <div className="p-3">
        <div className="row">
          <div className="col-4 position-relative">
            <img src="img/blueProjector.webp" style={styleProjector} alt="icone projector" />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["proj", 1] })}>
              <img src="img/textProblemProjType1.webp" className="img-fluid" alt="Type 1 Projection" />
            </button>
            <span>1 problème projeté</span>
          </div>
          <div className="col-4 position-relative">
            <img src="img/blueProjector.webp" style={styleProjector} alt="icone projector" />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["proj", 2] })}>
              <img src="img/textProblemProjType2.webp" className="img-fluid" alt="Type 2 Projection" />
            </button>
            <span>2 problèmes projetés (2 niveaux)</span>
          </div>
          <div className="col-4 position-relative">
            <img src="img/blueProjector.webp" style={styleProjector} alt="icone projector" />
            <button className="btn btn-outline-ternary" onClick={() => setEditionType({ problemType: ProblemTypeEnum.TextProblem, editionType: ["proj", 3] })}>
              <img src="img/textProblemProjType3.webp" className="img-fluid" alt="Type 3 Projection" />
            </button>
            <span>Plusieurs problèmes projetés</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditionTextProblemType;
