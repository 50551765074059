/* eslint-disable jsx-a11y/img-redundant-alt */
import { Controller, useForm } from "react-hook-form";
import { faFloppyDisk, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Checkbox, FormButtonLoading, ResizePhotoCanvas } from ".";
import Select, { GroupBase, Options } from "react-select";
import { PhotoTypeEnum, ProblemFormTypeEnum } from "../Utils";
import { useUtils } from "../hooks";
import { DefaultErrorsType, FormErrors, PhotoProblemFormValue, PhotoProblemType } from "../types";
import { Alert } from "react-bootstrap";

const defaultValues: PhotoProblemFormValue = {
    attachment: [],
    type: [],
    statement: "",
    nivel: "",
    public: "yes",
    imageData: "0;0;1;1",
    imageRotation: 0,
    addToPhoto: false,
    photoType: [],
};

const defaultErrors: Omit<DefaultErrorsType<PhotoProblemFormValue>, "imageData" | "imageRotation" | "addToPhoto"> = {
    attachment: {
        required: "Veuillez choisir une photo.",
    },
    type: {
        validate: (value) => value.length > 0 || "Veuillez choisir le ou les mots-clés de votre photo.",
    },
    statement: {
        required: "Veuillez renseigner l'énoncé du photo-problème.",
        maxLength: { value: 500, message: "Merci de saisir moins de 500 caractères." },
    },
    nivel: {
        required: "Veuillez renseigner le niveau du photo-problème.",
    },
    public: {
        required: "Veuillez choisir la visibilité de votre photo-problème.",
    },
    photoType: {
        validate: (value, formValues) => (value.length !== 0 && formValues.addToPhoto === true) || "Veuillez choisir le ou les mots-clés de la photo crée suite au photo-problem.",
    },
};

const options: Options<GroupBase<{ value: keyof typeof PhotoTypeEnum; label: PhotoTypeEnum }>> = [
    {
        label: "Espace et géométrie",
        options: [
            { value: "geom_1", label: PhotoTypeEnum.geom_1 },
            { value: "geom_2", label: PhotoTypeEnum.geom_2 },
            { value: "geom_3", label: PhotoTypeEnum.geom_3 },
            { value: "geom_4", label: PhotoTypeEnum.geom_4 },
            { value: "geom_5", label: PhotoTypeEnum.geom_5 },
            { value: "geom_6", label: PhotoTypeEnum.geom_6 },
            { value: "geom_7", label: PhotoTypeEnum.geom_7 },
            { value: "geom_8", label: PhotoTypeEnum.geom_8 },
            { value: "geom_9", label: PhotoTypeEnum.geom_9 },
        ],
    },
    {
        label: "Les mesures",
        options: [
            { value: "meas_1", label: PhotoTypeEnum.meas_1 },
            { value: "meas_2", label: PhotoTypeEnum.meas_2 },
            { value: "meas_3", label: PhotoTypeEnum.meas_3 },
            { value: "meas_4", label: PhotoTypeEnum.meas_4 },
            { value: "meas_5", label: PhotoTypeEnum.meas_5 },
            { value: "meas_6", label: PhotoTypeEnum.meas_6 },
            { value: "meas_7", label: PhotoTypeEnum.meas_7 },
            { value: "meas_8", label: PhotoTypeEnum.meas_8 },
        ],
    },
    {
        label: "Les nombres et numéro",
        options: [
            { value: "numb_1", label: PhotoTypeEnum.numb_1 },
            { value: "numb_2", label: PhotoTypeEnum.numb_2 },
            { value: "numb_3", label: PhotoTypeEnum.numb_3 },
            { value: "numb_4", label: PhotoTypeEnum.numb_4 },
            { value: "numb_5", label: PhotoTypeEnum.numb_5 },
            { value: "numb_6", label: PhotoTypeEnum.numb_6 },
        ],
    },
];

type PhotoProblemFormProps = {
    onFormSubmit: (data: PhotoProblemFormValue) => void;
    formErrors: FormErrors<PhotoProblemFormValue>;
    handleCancel: () => void;
    formType: ProblemFormTypeEnum;
    titleLabel: string;
    cancelLabel?: string;
    isLoading?: boolean;
    isSuccess?: boolean;
    problem?: PhotoProblemType;
};

function PhotoProblemForm({ onFormSubmit, formErrors, handleCancel, formType, titleLabel, cancelLabel = "Annuler", isLoading = false, isSuccess = false, problem = undefined }: PhotoProblemFormProps) {
    const {
        register,
        handleSubmit,
        setError,
        getValues,
        reset,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValues,
    });

    const { photoTypeToArray } = useUtils();

    // To set the errors to the form
    useEffect(() => {
        if (formErrors.length === 0) {
            reset(defaultValues);
        }
        formErrors.forEach(({ name, message }) => {
            setError(name, { message: message });
        });
    }, [formErrors, setError, reset]);

    // To reset the form if the mutation was successfull
    useEffect(() => {
        if (isSuccess) reset(defaultValues);
    }, [isSuccess, reset]);

    // To prepopulate the form based on default value (used in modify and review part, not in contribute)
    useEffect(() => {
        if (problem) {
            setValue("type", photoTypeToArray(problem.type));
            setValue("photoType", photoTypeToArray(problem.type));
            setValue("attachment", [process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment]);

            setValue("statement", problem.statement);
            setValue("nivel", problem.nivel);

            if (formType === ProblemFormTypeEnum.Review) {
                setValue("addToPhoto", true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [problem]);

    return (
        <div className="form-template" style={{ minWidth: "" }}>
            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                <div className="label-form">
                    <h1 className="h3 text-center">{titleLabel} un photo-problème</h1>
                </div>
                <div className="alert-form">{errors.root && <Alert variant="danger">{errors.root.message}</Alert>}</div>
                <div className="body-form canvas-size-max">
                    {/* Attachment */}
                    {!problem && (
                        <>
                            <p className="mb-2">1. Téléversez votre photo.</p>
                            <div className="mb-3 form-control-file">
                                <input
                                    type="file"
                                    {...register("attachment", defaultErrors.attachment)}
                                    id="photo_problem_attachment"
                                    className={"form-control" + (errors.attachment ? " is-invalid" : "")}
                                    required
                                    autoFocus
                                />
                                {errors.attachment && <div className="invalid-feedback d-block">{errors.attachment.message}</div>}
                            </div>
                        </>
                    )}
                    {!problem || formType === ProblemFormTypeEnum.Review ? (
                        <ResizePhotoCanvas target={watch("attachment")} setImageData={(data) => setValue("imageData", data)} setRotateData={(data) => setValue("imageRotation", data)} />
                    ) : (
                        <div className="text-center mb-3">
                            <img className="mw-100 h-auto" alt="Photo indisponible..." src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} />
                        </div>
                    )}

                    {/* Statement */}
                    {!problem && <p className="mb-2">2. Rédigez votre énoncé correspondant à la photo</p>}
                    <div className="mb-3 form-floating">
                        <textarea
                            {...register("statement", defaultErrors.statement)}
                            id="photo_problem_statement"
                            className={"form-control" + (errors.statement ? " is-invalid" : "")}
                            placeholder="Énoncé du photo-problème"
                            style={{ height: 150 }}
                            maxLength={500}
                            required
                        ></textarea>
                        <label htmlFor="photo_problem_statement" className="form-label">
                            Énoncé du photo-problème
                        </label>
                        <div id="photo_problem_statement_help" className="form-text mb-0">
                            {watch("statement").length}/500
                        </div>
                        {errors.statement && <div className="invalid-feedback d-block">{errors.statement.message}</div>}
                    </div>

                    {/* Type */}
                    {!problem && <p className="mb-2">3. Selectionnez tous les mots-clés correspondant au photo-problème.</p>}
                    <div className="mb-3" style={{ textAlign: "left" }}>
                        <Controller
                            control={control}
                            name="type"
                            rules={defaultErrors.type}
                            defaultValue={getValues("type")}
                            render={({ field: { onChange, value, name, ref } }) => (
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,
                                            borderColor: errors.type ? "#dc3546" : styles.borderColor,
                                            boxShadow: errors.type ? "0 0 0 #dc3546" : styles.boxShadow,
                                            ":hover": {
                                                borderColor: errors.type ? "#dc3546" : styles[":hover"]?.borderColor,
                                            },
                                        }),
                                    }}
                                    onChange={(val) => onChange(val.map((c) => c.value))}
                                    value={([] as any[]).concat(...options.map((c) => c.options.filter((c) => value.includes(c.value))))}
                                    ref={ref}
                                    name={name}
                                    noOptionsMessage={() => "Aucun résultat trouvé."}
                                    options={options}
                                    placeholder="Liste des mots-clés"
                                ></Select>
                            )}
                        />

                        {errors.type && <div className="invalid-feedback d-block">{errors.type.message}</div>}
                    </div>

                    {/* Niveau */}
                    {!problem && <p className="mb-2">4. Selectionnez le niveau de votre photo-problème.</p>}
                    <fieldset className="mb-3 text-center">
                        <Checkbox label="Cycle 1" name="nivel" value="c1" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="CP" name="nivel" value="cp" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="CE1" name="nivel" value="ce1" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="CE2" name="nivel" value="ce2" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="CM1" name="nivel" value="cm1" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="CM2" name="nivel" value="cm2" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        <Checkbox label="Sixième" name="nivel" value="6eme" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
                        {errors.nivel && <div className="invalid-feedback d-block">{errors.nivel.message}</div>}
                    </fieldset>

                    {formType === ProblemFormTypeEnum.Review && (
                        <div className="mb-3 form-check checkbox">
                            <input type="checkbox" {...register("addToPhoto")} className={"form-check-input" + (errors.addToPhoto ? " is-invalid" : "")} id="addToPhoto" />
                            <label className="form-check-label" htmlFor="addToPhoto">
                                Ajouter également aux photos
                            </label>
                            {errors.addToPhoto && <div className="invalid-feedback d-block">{errors.addToPhoto.message}</div>}
                        </div>
                    )}

                    {watch("addToPhoto") === true && formType === ProblemFormTypeEnum.Review && (
                        <div className="mb-3" style={{ textAlign: "left" }}>
                            <Controller
                                control={control}
                                name="photoType"
                                rules={defaultErrors.photoType}
                                defaultValue={getValues("photoType")}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                borderColor: errors.photoType ? "#dc3546" : styles.borderColor,
                                                boxShadow: errors.photoType ? "0 0 0 #dc3546" : styles.boxShadow,
                                                ":hover": {
                                                    borderColor: errors.photoType ? "#dc3546" : styles[":hover"]?.borderColor,
                                                },
                                            }),
                                        }}
                                        onChange={(val) => onChange(val.map((c) => c.value))}
                                        value={([] as any[]).concat(...options.map((c) => c.options.filter((c) => value.includes(c.value))))}
                                        ref={ref}
                                        name={name}
                                        noOptionsMessage={() => "Aucun résultat trouvé."}
                                        options={options}
                                        placeholder="Liste des mots-clés"
                                    ></Select>
                                )}
                            />

                            {errors.photoType && <div className="invalid-feedback d-block">{errors.photoType.message}</div>}
                        </div>
                    )}

                    {problem ? (
                        <div className="text-center">
                            <FormButtonLoading type="submit" isLoading={isLoading} label={titleLabel} className="btn btn-primary" />
                            <FormButtonLoading type="button" isLoading={isLoading} label={cancelLabel} onClick={handleCancel} className="btn btn-outline-ternary ms-2 ms-sm-5" icone={faTrash} />
                            {problem.email && <p className="mt-2">Proposé par : {problem.email}</p>}
                        </div>
                    ) : (
                        <>
                            <hr />
                            <div className="text-center mb-3">
                                <fieldset className="mb-3">
                                    <legend className="col-form-label">Visibilité du problème</legend>
                                    <Checkbox label="Public *" name="public" value="yes" register={register} error={errors.public} defaultErrors={defaultErrors.public} />
                                    <Checkbox label="Privé" name="public" value="no" register={register} error={errors.public} defaultErrors={defaultErrors.public} />
                                    {errors.public && <div className="invalid-feedback d-block">{errors.public.message}</div>}
                                </fieldset>

                                {watch("public") === "no" ? (
                                    <FormButtonLoading type="submit" isLoading={isLoading} label="Enregistrer" className="btn btn-primary" icone={faFloppyDisk} />
                                ) : (
                                    <FormButtonLoading type="submit" isLoading={isLoading} label="Soumettre" className="btn btn-primary" icone={faShare} />
                                )}
                                <FormButtonLoading
                                    type="button"
                                    isLoading={isLoading}
                                    label="Annuler"
                                    onClick={handleCancel}
                                    className="btn btn-outline-ternary ms-2 ms-sm-5"
                                    icone={faTrash}
                                    showLoading={false}
                                />
                            </div>
                            {watch("public") === "yes" && (
                                <span id="helpPublic" className="fs-6 fst-italic">
                                    * Le photo-problème ne sera publié qu'après validation de l'administrateur.
                                </span>
                            )}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
}

export default PhotoProblemForm;
