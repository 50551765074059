type TextProblemPlaceholderProps = {
    size: number;
};

function TextProblemPlaceholder({ size }: TextProblemPlaceholderProps) {
    return [...new Array(size)].map((e, index) => (
        <div key={index} className="col-12 placeholder-glow p-3 mb-3" style={{ borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
            <span className="placeholder col-4"></span>
            <hr />
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-5"></span>
            <hr />
            <div className="d-flex justify-content-between">
                <div className="col-6 text-start">
                    <span className="placeholder col-1"></span>
                    <span className="placeholder col-2 mx-3"></span>
                    <span className="placeholder col-1"></span>
                </div>
                <div className="col-6 text-end">
                    <span className="placeholder col-3 me-5"></span>
                    <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3 me-2"></button>
                    <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3"></button>
                </div>
            </div>
        </div>
    ));
}

type PhotoPlaceholderProps = {
    size: number;
};

function PhotoPlaceholder({ size }: PhotoPlaceholderProps) {
    return (
        <div className="row g-2">
            {[...new Array(size)].map((e, index) => (
                <div key={index} className="col-md-6 col-12">
                    <div className="placeholder-glow p-3" style={{ borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
                        <div className="placeholder w-100" style={{ height: 300 }}></div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div className="col-8">
                                <span className="placeholder col-4"></span>
                                <span className="placeholder col-6 ms-3"></span>
                            </div>
                            <div className="col-4 text-end">
                                <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3 me-2"></button>
                                <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3"></button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

type PhotoProblemPlaceholderProps = {
    size: number;
};

function PhotoProblemPlaceholder({ size }: PhotoProblemPlaceholderProps) {
    return (
        <div className="row g-2">
            {[...new Array(size)].map((e, index) => (
                <div key={index} className="col-md-6 col-12">
                    <div className="placeholder-glow p-3" style={{ borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
                        <div className="placeholder w-100" style={{ height: 300 }}></div>
                        <hr />
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-5"></span>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div className="col-6 text-start">
                                <span className="placeholder col-2"></span>
                                <span className="placeholder col-4 mx-3"></span>
                                <span className="placeholder col-6"></span>
                            </div>
                            <div className="col-6 text-end">
                                <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3 me-2"></button>
                                <button className="placeholder btn btn-primary btn-sm disabled py-0 px-3"></button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export { PhotoPlaceholder, PhotoProblemPlaceholder, TextProblemPlaceholder };
