/* eslint-disable jsx-a11y/img-redundant-alt */
import { useUtils } from "../hooks";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../Utils";
import { EnigmaType } from "../types";

type EnigmaProps = {
    problem: EnigmaType;
};

function Enigma({ problem }: EnigmaProps) {
    const { formatEnigmaType } = useUtils();

    const { openModal } = useModals();

    return (
        <div>
            <div className="text-center">
                <div className="d-inline-block">
                    <div className="d-table-cell align-middle" style={{ height: 300 }}>
                        <img
                            className="mw-100 mh-100 align-middle image-photo"
                            src={process.env.REACT_APP_BASE_API_URL + "/enigma/" + problem.attachment}
                            alt="Photo indisponible..."
                            onClick={() => openModal(ModalTypeEnum.Image, { attachment: problem.attachment, enigma: true })}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="d-flex flex-row justify-content-between">
                <div className="text-start">
                    <span className="badge text-bg-secondary text-black">{formatEnigmaType(problem.type)}</span>
                </div>
                <div className="text-end">
                    <span className="badge text-bg-primary text-light">Auteur : {problem.username}</span>
                </div>
            </div>
        </div>
    );
}

export default Enigma;
