const ProfilDataKey: [base: string] = ["profil"];
const ProfilGradeKey: [base: string, action: string] = [...ProfilDataKey, "grade"];
const ProfilActivityKey: [base: string, Action: string] = [...ProfilDataKey, "activity"];

export type ProfilGradeData = {
    gradeProgressBar: {
        step: [min: number, max: number, label: string][];
        actual: number;
        actualValidate: number;
    };
    isCertifie: boolean;
    urlBadge: string;
};

export type ProfilActivityData = {
    numberProblems: number;
    numberTextProblemsPublic: number;
    numberPhotosPublic: number;
    numberPhotoProblemsPublic: number;
    numberProblemComments: number;
    numberProblemsWaiting: number;
};

export { ProfilDataKey, ProfilGradeKey, ProfilActivityKey };
