import { useQuery } from "@tanstack/react-query";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import { ApiError, useAPI, useDocumentTitle } from "../hooks";
import { Enigma, EnigmaConsultForm, Pagination } from "../components";
import { LinearProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import * as Routes from "../routes";
import { EnigmaFormValues, EnigmaType } from "../types";
import { ProblemsTypeFiltersPageKey } from "./query";
import { Alert, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";

type ParamsType = {
    page: string;
};

type QueryData = {
    page: number;
    totalPage: number;
    totalProblems: number;
    problems: EnigmaType[];
};

function ConsultEnigma() {
    useDocumentTitle("Des centaines d'énigmes mathématiques avec un accès par filtres");

    const { page } = useParams<ParamsType>() as ParamsType;
    const { pathname } = useLocation();
    const regexPathname = /^(.*\/)[0-9]+$/gi.exec(pathname);

    const defaultFilters: EnigmaFormValues = {
        type: [],
        nivel: [],
    };

    const api = useAPI();
    const [showFilter, setShowFilter] = useState(false);
    const [filters, setFilters] = useState<EnigmaFormValues>(defaultFilters);

    const { isLoading, isError, error, data } = useQuery<QueryData, ApiError>(
        ProblemsTypeFiltersPageKey("enigma", filters, Number.parseInt(page)),
        () => api.problem.getEnigmas(Number.parseInt(page), filters),
        {
            staleTime: 60 * 1000, // 1 min
        }
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [filters]);

    return (
        <div className="m-auto" style={{ maxWidth: 960 }}>
            {/* =========================================================
                Buttons at the top for filters, basket and pdf generation
            ========================================================== */}
            <div className="d-flex justify-content-between bg-white p-3 mb-2 pb-2 position-sticky z-4" style={{ top: 40 }}>
                <div>
                    <button className="btn btn-primary" onClick={() => setShowFilter(true)}>
                        Filtres <FontAwesomeIcon icon={faAngleDoubleRight} className="text-secondary" />
                        {data && <span className="ms-2 badge bg-secondary text-black">{data.totalProblems}</span>}
                    </button>
                    {JSON.stringify(defaultFilters) !== JSON.stringify(filters) && (
                        <OverlayTrigger overlay={<Tooltip id="reset-filters">Supprimer les filtres actifs.</Tooltip>}>
                            <button className="d-inline-block btn btn-sm btn-danger py-0 ms-1" onClick={() => setFilters(defaultFilters)}>
                                X
                            </button>
                        </OverlayTrigger>
                    )}
                </div>
            </div>
            <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} style={{ width: 960 }}>
                <Offcanvas.Body>
                    <EnigmaConsultForm setShowFilter={setShowFilter} setFilters={setFilters} filters={filters} />
                </Offcanvas.Body>
            </Offcanvas>
            {/* ============================
                Displaying enigmas is pages
            ============================= */}
            {isLoading ? (
                <LinearProgress />
            ) : isError ? (
                <Alert variant={error.variant}>{error.message}</Alert>
            ) : data.problems.length < 1 ? (
                <Alert variant="warning">
                    {page === "1" ? (
                        <>Nous n'avons trouvé aucune énigmes qui répondent à vos critères.</>
                    ) : (
                        <>
                            Nous n'avons trouvé aucune énigmes sur cette page{" "}
                            <Link className="alert-link" to={generatePath(Routes.CONSULT_ENIGMA, { page: "1" })}>
                                page n°1.
                            </Link>
                        </>
                    )}
                </Alert>
            ) : (
                <>
                    <ul className="list-group list-group-problem-photo">
                        {data.problems.map((problem: EnigmaType) => (
                            <li key={problem.attachment} className="list-group-item mb-3" style={{ borderRadius: 5, border: "1px solid lightgrey", boxShadow: "0px 0px 5px" }}>
                                <Enigma problem={problem} />
                            </li>
                        ))}
                    </ul>
                    <Pagination page={Number.parseInt(page)} nbrPages={data?.totalPage} nbrPagesInARow={5} url={regexPathname?.[1]} />
                </>
            )}
        </div>
    );
}

export default ConsultEnigma;
