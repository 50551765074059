import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";
import { GenerateAiTypeEnum } from "../../../Utils";

class AdminAPI {
  async adminData() {
    try {
      const response = await getGetAxiosInstance().get("/admin");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getReviewProblem(index: number = 0) {
    try {
      const response = await getGetAxiosInstance().get("/admin/review", { params: { index: index } });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async review(idProblem: number, data: any, isPrivate: boolean, isRemove: boolean) {
    const queryParam = {
      reject: 0,
      remove: 0,
    };
    if (isRemove) {
      if (isPrivate) {
        queryParam.reject = 1;
      } else {
        queryParam.remove = 1;
      }
    }

    try {
      const response = await getPostPutAxiosInstance().put("/admin/review/" + idProblem, data, { params: queryParam });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async chart(temporality: string, startDay: string) {
    try {
      const response = await getGetAxiosInstance().get("/admin/visitor-diagram/" + temporality + "/" + startDay);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async sendEmail(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/admin/send-email", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async problemsAI(type: GenerateAiTypeEnum) {
    try {
      const response = await getGetAxiosInstance().get("/admin/problems-ai/" + type);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async generateAI(idProblem: number, type: GenerateAiTypeEnum = GenerateAiTypeEnum.ALL) {
    try {
      const response = await getPostPutAxiosInstance().put("/admin/generate-ai/" + idProblem + "/" + type);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default AdminAPI;
