import * as Routes from "../../routes";
import { Link } from "react-router-dom";
import { ProblemTypeEnum } from "../../Utils";
import { ModalPropsDefault } from "../../types";
import { Alert, Modal } from "react-bootstrap";

type AskNewContributionModalProps = {
    message: string;
    type: ProblemTypeEnum;
};

function AskNewContributionModal({ show, closeModal, message, type }: ModalPropsDefault & AskNewContributionModalProps) {
    return (
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Body>
                <Alert variant="success">{message}</Alert>
                <div className="text-center">
                    {type === ProblemTypeEnum.TextProblem && <h3 className="fw-bold m-3">Souhaitez-vous soumettre un autre problème ?</h3>}
                    {type === ProblemTypeEnum.Photo && <h3 className="fw-bold m-3">Souhaitez-vous soumettre une autre photo ?</h3>}
                    {type === ProblemTypeEnum.PhotoProblem && <h3 className="fw-bold m-3">Souhaitez-vous soumettre un autre photo-problème ?</h3>}
                    <button className="btn btn-primary me-2" onClick={closeModal}>
                        Bien sûr
                    </button>
                    <Link className="btn btn-ternary" to={Routes.HOME} onClick={closeModal}>
                        Non merci
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AskNewContributionModal;
