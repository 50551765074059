import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI, useDocumentTitle } from "../hooks";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../Utils";
import { MutationData } from "../types";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { LinearProgress } from "@mui/material";

function Console() {
    useDocumentTitle("Console - M@ths en-vie");
    const api = useAPI();
    const { openModal } = useModals();

    const cacheClear = useMutation<MutationData, ApiError>({
        mutationFn: () => {
            executeSql.reset();
            return api.console.cacheClear();
        },
    });

    const executeSql = useMutation<MutationData, ApiError>({
        mutationFn: () => {
            cacheClear.reset();
            return api.console.executeSql();
        },
    });

    /* Montly emails */
    const mutation = useMutation<MutationData, ApiError, { offset: number; limit: number }>({
        mutationFn: (data) => {
            return api.console.montlyEmails(data.offset, data.limit);
        },
    });

    const [value, setValue] = useState(0);
    const [results, setResults] = useState<{ message: string; status: HttpStatusCode }[]>([]);

    async function lunch() {
        setResults([]);

        let numberOfUsers = 0;

        try {
            numberOfUsers = await api.console.montlyEmailsCount();
        } catch (error) {
            return;
        }

        const AMOUT_USER_PER_REQUEST = 100;

        let promisesMutation: Promise<MutationData>[] = [];
        for (var i = 0; i < Math.ceil(numberOfUsers / AMOUT_USER_PER_REQUEST); i += 1) {
            const promise = mutation.mutateAsync({ offset: i * AMOUT_USER_PER_REQUEST, limit: AMOUT_USER_PER_REQUEST });
            promisesMutation.push(promise);

            promise
                .then((data) => {
                    setResults((old) => [...old, { message: data.message, status: 200 }]);
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        setResults((old) => [...old, { message: error.message, status: error.code }]);
                    }
                })
                .finally(() => {
                    setValue((old) => old + 100 / Math.ceil(numberOfUsers / AMOUT_USER_PER_REQUEST));
                });
        }

        try {
            await Promise.all(promisesMutation);
        } catch (error) {}

        setTimeout(() => {
            setValue(0);
        }, 2000);
    }

    return (
        <>
            {cacheClear.isSuccess && <div>{cacheClear.data.message}</div>}
            {executeSql.isSuccess && <div>{executeSql.data.message}</div>}
            {cacheClear.isError && <div>{cacheClear.error.message}</div>}
            {executeSql.isError && <div>{executeSql.error.message}</div>}
            {(cacheClear.isLoading || executeSql.isLoading) && (
                <div>
                    Execution en cours <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />
                </div>
            )}
            <div className="mt-3">
                <button className="btn btn-danger me-1" onClick={() => cacheClear.mutate()}>
                    Clear the cache
                </button>
                <button className="btn btn-danger me-1" onClick={() => executeSql.mutate()}>
                    Execute sql command
                </button>
                <button className="btn btn-primary" onClick={() => openModal(ModalTypeEnum.ConfirmUser)}>
                    <FontAwesomeIcon icon={faCheck} /> Accepter un utilisateur
                </button>
            </div>

            {/* Montly emails */}
            <div className="text-center">
                <button className="btn btn-primary" onClick={lunch} disabled={mutation.isLoading}>
                    <FontAwesomeIcon icon={faCalculator} /> Envoyer les emails
                </button>
                {<LinearProgress color="success" className="my-3 w-50 mx-auto" value={value} variant="determinate" />}
                {results.map((result, index) => (
                    <div key={index} className={result.status === 200 ? "text-success" : "text-danger"}>
                        {result.message}
                    </div>
                ))}
            </div>
        </>
    );
}

export default Console;
