enum PhotoTypeEnum {
    geom_1 = "Cercles, disques, sphères et cylindres",
    geom_2 = "Polygones",
    geom_3 = "Formes géométriques",
    geom_4 = "Perpendiculaires",
    geom_5 = "Parallèles",
    geom_6 = "Solides",
    geom_7 = "Symétrie",
    geom_8 = "Algorithmes",
    geom_9 = "Angles",

    meas_1 = "Longueurs",
    meas_2 = "Vitesses",
    meas_3 = "Masses",
    meas_4 = "Volumes",
    meas_5 = "Temps",
    meas_6 = "Températures",
    meas_7 = "Prix",
    meas_8 = "Aires et périmètres",

    numb_1 = "Numéros",
    numb_2 = "Quantités nombrables",
    numb_3 = "Décimaux",
    numb_4 = "Fractions",
    numb_5 = "Proportionnalité",
    numb_6 = "Pourcentages",
}

export default PhotoTypeEnum;
