import { useForm } from "react-hook-form";
import { useAuth } from "../contexts/auth";
import { ApiError, useAPI } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { MutationData } from "../types";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";

type FormValues = {
  subscribed: boolean;
};

const defaultValues: FormValues = {
  subscribed: true,
};

function SubscribeEmails() {
  const { user, setIsSubscribedEmails } = useAuth();
  const api = useAPI();
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (user) setValue("subscribed", user.isEmailsSubscribed);
  }, [user, setValue]);

  const subscribeEmails = useMutation<MutationData & { isEmailsSubscribed?: boolean }, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.profil.subscribeEmails(data);
    },
    onSuccess: (data) => {
      reset();
      if (data.isEmailsSubscribed !== undefined) {
        setIsSubscribedEmails(data.isEmailsSubscribed);
      }
    },
    onError: () => {
      reset();
    },
  });

  const onSubmit = (data: FormValues) => {
    data.subscribed = !data.subscribed;
    subscribeEmails.mutate(data);
  };

  return (
    <form noValidate>
      {subscribeEmails.isError && <Alert variant={subscribeEmails.error.variant}>{subscribeEmails.error.message}</Alert>}
      <div className="form-check checkbox">
        <input type="checkbox" {...register("subscribed")} onChange={handleSubmit(onSubmit)} className="form-check-input" id="subscribe" disabled={subscribeEmails.isLoading} />
        <label className="form-check-label" htmlFor="subscribe">
          Inscription à la lettre d'information mensuelle
        </label>
      </div>
    </form>
  );
}

export default SubscribeEmails;
