import { useUtils } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { ModalPropsDefault, TextProblemType } from "../../types";
import { CloseButton, Modal } from "react-bootstrap";

type TextProblemProjectionModalProps = {
  problems: TextProblemType[];
  type: number;
};

function TextProblemProjectionModal({ show, closeModal, problems, type }: ModalPropsDefault & TextProblemProjectionModalProps) {
  const handleClose = () => {
    closeModal();
  };

  const { addBoldToQuestion, sanitize } = useUtils();

  return (
    <Modal show={show} onHide={handleClose} fullscreen>
      <Modal.Header>
        <button className="position-absolute end-0 me-5 btn btn-link link-light text-decoration-none" onClick={closeModal}>
          Fermer
        </button>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {type === 1 || problems.length === 1 ? (
          <div style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: 30 }}>
            <h1 className="mb-5 fw-bold" style={{ fontSize: 75 }}>
              {problems[0].title}
            </h1>
            <div style={{ fontSize: 50 }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problems[0].statement))}></div>
          </div>
        ) : type === 2 ? (
          <>
            <div className="text-center">
              <button
                className="btn btn-primary mx-auto my-0"
                onClick={() => {
                  var problems = document.getElementsByClassName("problem");
                  var problemDom = document.querySelector("body .problems");
                  if (problemDom) problemDom.prepend(problems[1]);
                }}
                style={{ width: 40 }}
              >
                <FontAwesomeIcon icon={faArrowsLeftRight} />
              </button>
            </div>

            <div className="d-flex problems">
              {problems.slice(-2).map((problem) => (
                <div key={problem.id} className="problem w-50" style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: 30 }}>
                  <h1 className="mb-2 fw-bold" style={{ fontSize: 50 }}>
                    {problem.title}
                  </h1>
                  <div style={{ fontSize: 40 }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problem.statement))}></div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="d-flex problems flex-column">
            {problems.reverse().map((problem, index) => (
              <div className="problem d-flex" key={index}>
                <div style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: "8px -10px 8px 30px", backgroundColor: "#3362B2" }}>
                  <span className="align-middle d-inline-block fw-bold text-white" style={{ fontSize: 40 }}>
                    {index + 1}
                  </span>
                  <span className="align-middle d-inline-block h-100"></span>
                </div>
                <div style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: "8px 30px" }}>
                  <h1 className="mb-2 fw-bold" style={{ fontSize: 30 }}>
                    {problem.title}
                  </h1>
                  <div style={{ fontSize: 25 }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problem.statement))}></div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default TextProblemProjectionModal;
