import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../hooks";
import { NotificationTypeEnum } from "../Utils";
import { useAuth } from "./auth";

type ContextData = {
    notificationsNumber: NotificationData | undefined;
};
const NotificationsContext = createContext<ContextData | undefined>(undefined);

const NotificationsDataKey: [base: string] = ["notification"];
const NotificationsTypeKey = (type: NotificationTypeEnum): [base: string, type: NotificationTypeEnum] => [...NotificationsDataKey, type];
const NotificationsTypePageKey = (type: NotificationTypeEnum, page: string): [base: string, type: NotificationTypeEnum, page: string] => [...NotificationsTypeKey(type), page];

type NotificationData = {
    total: number;
    types: {
        [NotificationTypeEnum.Like]: number;
        [NotificationTypeEnum.Comment]: number;
        [NotificationTypeEnum.Message]: number;
    };
};

const NotificationsProvider = ({ children }: React.PropsWithChildren) => {
    const api = useAPI();
    const { user } = useAuth();

    const { data: notificationsNumber } = useQuery<NotificationData>(NotificationsDataKey, api.profil.getNotificationsNumber, {
        refetchOnWindowFocus: true,
        enabled: !!user,
    });

    const value = useMemo(() => ({ notificationsNumber }), [notificationsNumber]);

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

const useNotifications = () => {
    const object = useContext(NotificationsContext);
    if (!object) {
        throw new Error("useNotifications must be used within a Provider");
    }

    return object;
};

export { NotificationsProvider, useNotifications, NotificationsDataKey, NotificationsTypeKey, NotificationsTypePageKey };
