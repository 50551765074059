import { ApiError, useAPI, useDocumentTitle } from "../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { DefaultErrorsType, MutationData } from "../types";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useModals } from "../contexts/modals";
import { ModalTypeEnum } from "../Utils";

type FormValues = {
    schoolNameAndCity: string;
    nivel: string;
    statement: string;
};

const defaultValues: FormValues = {
    schoolNameAndCity: "",
    nivel: "",
    statement: "",
};

const defaultErrors: DefaultErrorsType<FormValues> = {
    schoolNameAndCity: {
        required: "Veuillez renseigner le nom de votre école et de votre ville.",
        maxLength: { value: 64, message: "Veuillez saisir moins de 64 caractères." },
    },
    nivel: {
        required: "Veuillez renseigner le niveau de votre classe.",
        maxLength: { value: 16, message: "Le niveau doit contenir moins de 16 caractères." },
    },
    statement: {
        required: "Veuillez renseigner l'énoncé du problème.",
        maxLength: { value: 500, message: "Veuillez saisir moins de 500 caractères." },
    },
};

type QueryData = {
    counter: number;
    lastProblem: string;
};

function MathWeek() {
    const api = useAPI();
    const queryClient = useQueryClient();
    const { openModal } = useModals();

    useDocumentTitle("Semaine des mathématiques - M@ths en-vie.");

    const [heightBody, setHeightBody] = useState("100vh");

    const resize = () => {
        const header = document.getElementById("masthead");
        setHeightBody("calc(100vh - " + header?.clientHeight + "px)");
    };

    useEffect(() => {
        window.addEventListener("resize", resize, false);
        resize();

        return () => {
            window.removeEventListener("resize", resize, false);
        };
    }, []);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValues,
    });

    const { data, isSuccess } = useQuery<QueryData>(["mathWeek", "counter"], api.mathWeek.counter, {
        refetchOnReconnect: false,
        refetchOnWindowFocus: true,
    });

    const addMathWeekProblem = useMutation<MutationData, ApiError<FormValues>, FormValues>({
        mutationFn: (data) => {
            return api.mathWeek.add(data);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(["mathWeek", "counter"]);
            reset();
            openModal(ModalTypeEnum.MathWeekCongrats, { message: data.message });
        },
        onError: (data) => {
            data.formErrors.forEach(({ name, message }) => {
                setError(name, { message: message });
            });
        },
    });

    const onSubmit = (data: FormValues) => {
        addMathWeekProblem.mutate(data);
    };

    return (
        <div
            style={{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                position: "absolute",
                flexDirection: "column",
                justifyContent: "space-between",
                contain: "layout size style",
                overflowY: "auto",
                overflowX: "hidden",
                zIndex: 0,
                backgroundColor: "#018FA6",
            }}
        >
            <div className="h-100 p-0 m-0 container-fluid">
                <header id="masthead" role="banner">
                    <a className="home-link" href="https://www.mathsenvie.fr/" title="M@ths en-vie" rel="home">
                        <img src="img/headerImage.webp" alt="M@ths en-vie" width="960" height="180" />
                    </a>
                    <div className="bg-ternary text-center text-white" style={{ height: 40, lineHeight: "38px", fontSize: "calc(0.5rem + 1vw)" }}>
                        Semaine des mathématiques 2024 - "L'important, c'est de participer"
                    </div>
                </header>
                <div className="row" style={{ height: heightBody, paddingTop: "2vh", paddingBottom: "2vh" }}>
                    <div className="d-flex justify-content-center" style={{ height: "25%", backgroundColor: "#05788B" }}>
                        <img className="mw-100 mh-100 align-self-center" src="img/maths_week_objectif2024.webp" alt="M@ths en-vie" />
                    </div>
                    <div className="position-relative" style={{ height: "50%" }}>
                        <div className="fw-bold text-center position-absolute w-100 fs-2">Nombre de problèmes ajoutés</div>
                        <div className="d-flex justify-content-between h-100" style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
                            <div className="d-flex text-start justify-content-start" style={{ width: "25%" }}>
                                <img className="mw-100 mh-100 align-self-center" src="img/maths_week_anneaux.webp" alt="M@ths en-vie" />
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="align-self-center">
                                    {isSuccess &&
                                        Array.from(String(data.counter)).map((digit, index) => (
                                            <span
                                                key={index}
                                                className="bg-dark text-white d-inline-block px-1 py-0 rounded-3"
                                                style={{ margin: 1, animation: "problemCounter 0.3s linear " + (index + 1), fontSize: "calc(min(10vh, 9vw))" }}
                                            >
                                                {digit}
                                            </span>
                                        ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end" style={{ width: "25%" }}>
                                <img className="mw-100 mh-100 align-self-center" src="img/maths_week_mascotte.webp" alt="M@ths en-vie" />
                            </div>
                        </div>
                        <div className="fw-bold text-center position-absolute w-100 bottom-0 fs-2">Dernier problème publié</div>
                    </div>
                    <div className="w-100 text-center fw-bold text-white" style={{ height: "25%", backgroundColor: "#05788B", fontSize: "calc(0.8vw + 0.9vh" }}>
                        {isSuccess && data.lastProblem}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MathWeek;
