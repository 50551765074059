import { Suspense } from "react";
import { useDocumentTitle } from "../../hooks";
import { Chart } from "../../components";

function AdminHome() {
    useDocumentTitle("Admin - M@ths en-vie");

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Chart />
        </Suspense>
    );
}

export default AdminHome;
