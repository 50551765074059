import { Link, NavLink, Outlet, generatePath, matchPath, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import * as Routes from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faGear } from "@fortawesome/free-solid-svg-icons";
import "./Profil.css";
import { useNotifications } from "../../contexts/notifications";
import { NotificationTypeEnum } from "../../Utils";

function ProfilLayout() {
  const { user } = useAuth();
  const { notificationsNumber } = useNotifications();
  const location = useLocation();

  const isNotifications = matchPath({ path: Routes.PROFIL_NOTIFICATIONS, end: false }, location.pathname);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3" id="main-profil">
          <div className="text-center profil-image" style={{ backgroundImage: "url(img/user.webp" }}></div>
          <div className="mt-4">
            <p className="fw-bold fs-4 lh-sm">
              {user?.username}
              {user?.grade !== 0 && <span className="badge bg-secondary text-black ms-2">{user?.grade}+</span>}
            </p>
            <p className="fs-5">{user?.email}</p>
            {/* <div className="d-grid gap-2 mt-2">
                            <button className="btn btn-outline-ternary" type="button">
                                Modifier le profil
                            </button>
                        </div> */}
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="card">
            <div className="card-header bg-ternary">
              <ul className="nav nav-tabs card-header-tabs" style={{ marginRight: "-1rem" }}>
                <li className="nav-item">
                  <NavLink to={Routes.PROFIL_ACTIVITY} className="nav-link">
                    Activité
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={Routes.PROFIL_GRADE} className="nav-link">
                    Mes grades
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={Routes.PROFIL_PARAMETERS} className="nav-link">
                    <FontAwesomeIcon icon={faGear} />
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Link to={generatePath(Routes.PROFIL_NOTIFICATIONS, { type: NotificationTypeEnum.Like, page: "1" })} className={"nav-link position-relative" + (isNotifications ? " active" : "")}>
                    <FontAwesomeIcon icon={faBell} />
                    {notificationsNumber && notificationsNumber.total > 0 && (
                      <span className="position-absolute translate-middle badge rounded-pill bg-danger" style={{ left: "90%" }}>
                        {notificationsNumber.total}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilLayout;
