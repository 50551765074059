/**
 * All available routes for the app
 */

export const HOME = "/";

export const PROBLEM = "/problem/:id";

export const _CONSULT = "/consult/:type";
export const CONSULT = `${_CONSULT}/:page?`;

export const CONSULT_ENIGMA = "/consult/enigma/:page?";

export const _EDITION = "/edition/:type";
export const EDITION = `${_EDITION}/:page?`;

export const CONTRIBUTE = "/add/:type";

export const HELP = "/help";
export const HELP_TEXT_PROBLEM = `${HELP}/text-problem`;
export const HELP_PHOTO = `${HELP}/photo`;
export const HELP_PHOTO_PROBLEM = `${HELP}/photo-problem`;
export const HELP_ENIGMA = `${HELP}/enigma`;

export const PROFIL = "/profil";
export const PROFIL_ACTIVITY = `${PROFIL}/activity`;
export const PROFIL_GRADE = `${PROFIL}/grade`;
export const PROFIL_PARAMETERS = `${PROFIL}/parameters`;
export const PROFIL_NOTIFICATIONS = `${PROFIL}/notifications/:type/:page?`;

export const ADMIN = "/admin";
export const ADMIN_REVIEW = `${ADMIN}/review`;
export const ADMIN_MAIL = `${ADMIN}/mail`;
export const ADMIN_MESSAGE = `${ADMIN}/message`;
export const ADMIN_CSV = `${ADMIN}/csv`;
export const ADMIN_AI = `${ADMIN}/ai`;

export const CONSOLE = "/console";

export const MATH_WEEK = "/semaine-des-maths";

// Others url

export const APPS = process.env.REACT_APP_APPS_URL as string;
export const APPS_LOGIN = process.env.REACT_APP_APPS_URL + "/login";
export const APPS_REGISTER = process.env.REACT_APP_APPS_URL + "/register";

export const APPS_PROFIL_PARAMETERS = process.env.REACT_APP_APPS_URL + "/profil/parameters";

export const APPS_LEGAL_NOTICES = process.env.REACT_APP_APPS_URL + "/mentions-legales";
export const APPS_PRIVACY = process.env.REACT_APP_APPS_URL + "/privacy";
export const APPS_CGU = process.env.REACT_APP_APPS_URL + "/cgu";
export const APPS_CONTACT = process.env.REACT_APP_APPS_URL + "/contact";
export const APPS_CREDITS = process.env.REACT_APP_APPS_URL + "/credits";

// Redirections urls

export const OLD_HOME = "/public";
