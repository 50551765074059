import { Modal } from "react-bootstrap";
import { ModalPropsDefault } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type MathWeekCongratsModalProps = {
  message: string;
};

function MathWeekCongratsModal({ show, closeModal, message }: ModalPropsDefault & MathWeekCongratsModalProps) {
  return (
    <Modal show={show} onHide={closeModal} centered={true} size="lg" style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}>
      <Modal.Header closeButton />
      <Modal.Body>
        <div>
          <p className="text-center fw-bold fs-4">{message}</p>
          <div className="text-center mt-5">
            <FontAwesomeIcon className="text-success" size="10x" icon={faCheckCircle} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MathWeekCongratsModal;
