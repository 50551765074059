import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  AskNewContributionModal,
  ConfirmUserModal,
  ImageModal,
  MathWeekCongratsModal,
  MessageModal,
  PhotoProblemProjectionModal,
  ProblemCommentsModal,
  ProblemModifyModal,
  ProblemPublishModal,
  ProblemRemoveModal,
  ProblemReportModal,
  ResizePhotoModal,
  TextProblemProjectionModal,
  ValidateResponseModal,
} from "../components/Modal";
import { ModalTypeEnum } from "../Utils";
import { ModalPropsDefault } from "../types";
import { useLocation } from "react-router-dom";

type GetRequiredKeys<T> = { [K in keyof T as undefined extends T[K] ? never : K]: T[K] };
type MakeOptionalIfEmpty<T> = keyof T extends never ? [] : keyof GetRequiredKeys<T> extends never ? [T?] : [T];

type ModalsPropsAdditional<T extends keyof typeof modalsElements> = Omit<React.ComponentProps<(typeof modalsElements)[T]["component"]>, keyof ModalPropsDefault>;

type OpenModal = <T extends keyof typeof modalsElements>(modal: T, ...[props]: MakeOptionalIfEmpty<ModalsPropsAdditional<T>>) => void;
type CloseModal = (modal: ModalTypeEnum) => void;
type IsModalOpen = (modal: ModalTypeEnum) => boolean;
type SaveData = (modal: ModalTypeEnum, data: any) => void;

type ContextData = {
  openModal: OpenModal;
  closeModal: CloseModal;
  isModalOpen: IsModalOpen;
};
const ModalsContext = createContext<ContextData | undefined>(undefined);

/**
 * The new modals need to be added here and only here
 */
const modalsElements = {
  [ModalTypeEnum.AskNewContribution]: { name: ModalTypeEnum.AskNewContribution, component: AskNewContributionModal },
  [ModalTypeEnum.ConfirmUser]: { name: ModalTypeEnum.ConfirmUser, component: ConfirmUserModal },
  [ModalTypeEnum.Image]: { name: ModalTypeEnum.Image, component: ImageModal },
  [ModalTypeEnum.MathWeekCongrats]: { name: ModalTypeEnum.MathWeekCongrats, component: MathWeekCongratsModal },
  [ModalTypeEnum.Message]: { name: ModalTypeEnum.Message, component: MessageModal },
  [ModalTypeEnum.PhotoProblemProjection]: { name: ModalTypeEnum.PhotoProblemProjection, component: PhotoProblemProjectionModal },
  [ModalTypeEnum.ProblemComments]: { name: ModalTypeEnum.ProblemComments, component: ProblemCommentsModal },
  [ModalTypeEnum.ProblemModify]: { name: ModalTypeEnum.ProblemModify, component: ProblemModifyModal },
  [ModalTypeEnum.ProblemPublish]: { name: ModalTypeEnum.ProblemPublish, component: ProblemPublishModal },
  [ModalTypeEnum.ProblemRemove]: { name: ModalTypeEnum.ProblemRemove, component: ProblemRemoveModal },
  [ModalTypeEnum.ProblemReport]: { name: ModalTypeEnum.ProblemReport, component: ProblemReportModal },
  [ModalTypeEnum.ResizePhoto]: { name: ModalTypeEnum.ResizePhoto, component: ResizePhotoModal },
  [ModalTypeEnum.ValidateResponse]: { name: ModalTypeEnum.ValidateResponse, component: ValidateResponseModal },
  [ModalTypeEnum.TextProblemProjection]: { name: ModalTypeEnum.TextProblemProjection, component: TextProblemProjectionModal },
};

type ModalsState = {
  [modal: string]: {
    _show: boolean;
    show: boolean;
    savedData: any | null;
    props: any | null;
  };
};

const ModalsProvider = ({ children }: React.PropsWithChildren) => {
  const [modals, setModals] = useState<ModalsState>(Object.values(ModalTypeEnum).reduce((acc, modal) => ({ ...acc, [modal]: { _show: false, show: false, savedData: null, props: null } }), {}));

  const openModal: OpenModal = (modal, ...props) => {
    setModals((m) => ({
      ...m,
      [modal]: { _show: true, show: true, savedData: m[modal].savedData, props: props[0] ?? null },
    }));
  };

  const closeModal: CloseModal = (modal) => {
    setModals((m) => ({
      ...m,
      [modal]: {
        ...m[modal],
        show: false,
      },
    }));
    setTimeout(() => {
      setModals((m) => ({
        ...m,
        [modal]: {
          ...m[modal],
          props: null,
          _show: false,
        },
      }));
    }, 200);
  };

  const isModalOpen: IsModalOpen = useCallback((modal) => modals[modal].show, [modals]);

  const saveData: SaveData = (modal, dataToSave) => {
    setModals((m) => ({
      ...m,
      [modal]: { ...m[modal], savedData: dataToSave },
    }));
  };

  const modalsTemplate = Object.values(modalsElements).map((element) => {
    if (modals[element.name]._show) {
      return (
        <element.component
          key={element.name}
          closeModal={() => closeModal(element.name)}
          show={modals[element.name].show}
          saveData={(data: any) => saveData(element.name, data)}
          savedData={modals[element.name].savedData}
          {...modals[element.name].props}
        />
      );
    }

    return null;
  });

  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      Object.keys(modals).forEach(function (modal) {
        if (isModalOpen(modal as ModalTypeEnum)) {
          closeModal(modal as ModalTypeEnum);
        }
      });
    }
  }, [location.pathname]);

  const value = useMemo(() => ({ openModal, closeModal, isModalOpen }), [isModalOpen]);

  return (
    <ModalsContext.Provider value={value}>
      {modalsTemplate}
      {children}
    </ModalsContext.Provider>
  );
};

const useModals = () => {
  const object = useContext(ModalsContext);
  if (!object) {
    throw new Error("useModals must be used within a Provider");
  }
  return object;
};

export { ModalsProvider, useModals };
