import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ModalPropsDefault, MutationData } from "../../types";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { useEffect } from "react";

type FormValues = {
  email: string;
  notifyUser: boolean;
};

const defaultValues: FormValues = {
  email: "",
  notifyUser: false,
};

function ConfirmUserModal({ show, closeModal, saveData, savedData }: Readonly<ModalPropsDefault<FormValues>>) {
  const api = useAPI();

  const handleClose = () => {
    setTimeout(() => {
      confirmUser.reset();
    }, 200);
    saveData(getValues());
    closeModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    reset(savedData);
  }, [reset, savedData]);

  const confirmUser = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.console.confirmUser(data);
    },
    onSuccess: () => {
      reset();
      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  const onSubmit = (data: FormValues) => {
    confirmUser.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h5>Accepter un utilisateur</h5>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {confirmUser.isError && <Alert variant={confirmUser.error.variant}>{confirmUser.error.message}</Alert>}
        {confirmUser.isSuccess && <Alert variant="success">{confirmUser.data.message}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <div className="form-floating">
              <input
                type="text"
                {...register("email", {
                  required: { value: true, message: "Veuillez saisir l'email de l'utilisateur." },
                })}
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                required
              />
              <label htmlFor="inputEmail" className="form-label">
                Email
              </label>
            </div>
            {errors.email && <div className="invalid-feedback d-block">{errors.email.message}</div>}
          </div>
          <div className="mb-3 form-check checkbox">
            <input type="checkbox" className="form-check-input" {...register("notifyUser")} id="notifyUser" />
            <label className="form-check-label" htmlFor="notifyUser">
              Notifier l'utilisateur
            </label>
          </div>
          <button type="submit" id="submitFormModal" className="d-none"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Annuler
        </button>
        <label htmlFor="submitFormModal" className={"btn btn-primary" + (confirmUser.isLoading ? " disabled" : "")}>
          Accepter {confirmUser.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </label>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmUserModal;
