/* eslint-disable jsx-a11y/img-redundant-alt */
import { Modal } from "react-bootstrap";
import { ModalPropsDefault } from "../../types";

type ImageModalProps = {
  attachment: string;
  enigma?: boolean;
};

function ImageModal({ show, closeModal, attachment, enigma = false }: ModalPropsDefault & ImageModalProps) {
  return (
    <Modal show={show} onHide={closeModal} size="xl">
      <Modal.Body>
        <div className="text-center">
          <img
            className="object-fit-contain w-100 align-middle"
            style={{ maxHeight: "75vh" }}
            src={process.env.REACT_APP_BASE_API_URL + (enigma ? "/enigma/" : "/photos/") + attachment}
            alt="Photo indisponible..."
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ImageModal;
