import { IconDefinition, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type FormButtonLoadingProps = {
    isLoading: boolean;
    label: string;
    showLoading?: boolean;
    icone?: IconDefinition;
};

function FormButtonLoading({ isLoading, label, showLoading = true, icone = undefined, ...props }: FormButtonLoadingProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button disabled={isLoading} {...props}>
            {icone && <FontAwesomeIcon icon={icone} />} {label}
            {isLoading && showLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </button>
    );
}

export default FormButtonLoading;
