import { faAngleDoubleLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as Routes from "../routes";
import { EnigmaTypeEnum } from "../Utils";
import { CheckboxArray } from "./Checkbox";
import { EnigmaFormValues } from "../types";

type EnigmaConsultFormProps = {
    setFilters: (filters: EnigmaFormValues) => void;
    setShowFilter: Dispatch<SetStateAction<boolean>>;
    filters: EnigmaFormValues;
};

function EnigmaConsultForm({ setFilters, setShowFilter, filters }: EnigmaConsultFormProps) {
    const { register, handleSubmit, reset } = useForm({
        defaultValues: filters,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (filters !== null) {
            reset(filters, { keepDefaultValues: true });
        }
    }, [filters, reset]);

    const onSubmit = (data: EnigmaFormValues) => {
        setFilters(data);
        setShowFilter(false);
        navigate(generatePath(Routes.CONSULT_ENIGMA, { page: "1" }), { replace: true });
    };

    return (
        <div className="form-template form-consult-problem">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="label-form position-relative">
                    <h1 className="h3 text-center">Consulter les énigmes</h1>
                    <button type="button" style={{ right: 25, top: "25%" }} className="btn btn-primary position-absolute" aria-label="Close" onClick={() => setShowFilter(false)}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                </div>
                <div className="alert-form"></div>
                <div className="body-form">
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <CheckboxArray name="type" register={register} value="algebriques" label={EnigmaTypeEnum.algebriques} />
                            <CheckboxArray name="type" register={register} value="algorithmiques" label={EnigmaTypeEnum.algorithmiques} />
                            <CheckboxArray name="type" register={register} value="denombrement" label={EnigmaTypeEnum.denombrement} />
                            <CheckboxArray name="type" register={register} value="geometrie" label={EnigmaTypeEnum.geometrie} />
                            <CheckboxArray name="type" register={register} value="logique" label={EnigmaTypeEnum.logique} />
                            <CheckboxArray name="type" register={register} value="optimisation" label={EnigmaTypeEnum.optimisation} />
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center">
                        <CheckboxArray name="nivel" register={register} value="1" label="GS/CP" className="ms-1 me-2" />
                        <CheckboxArray name="nivel" register={register} value="2" label="CE1/CE2" className="ms-1 me-2" />
                        <CheckboxArray name="nivel" register={register} value="3" label="Cycle 3" className="ms-1 me-2" />
                    </div>
                    <div className="text-center">
                        <button className="btn btn-primary mt-2" type="submit">
                            <FontAwesomeIcon icon={faFilter} /> Filtrer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EnigmaConsultForm;
