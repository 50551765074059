import { useMutation } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ResizePhotoCanvas } from "..";
import { ModalPropsDefault, MutationData, PhotoProblemType, PhotoType } from "../../types";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { useMemo } from "react";

type ResizePhotoModalProps = {
  problem: PhotoType | PhotoProblemType;
};

type FormValues = {
  addLogo: boolean;
  imageData: `${number};${number};${number};${number}`;
  imageRotation: number;
};

const defaultValues: FormValues = {
  addLogo: false,
  imageData: "0;0;1;1",
  imageRotation: 0,
};

function ResizePhotoModal({ show, closeModal, problem }: ModalPropsDefault & ResizePhotoModalProps) {
  const api = useAPI();

  const handleClose = () => {
    setTimeout(() => {
      resizePhoto.reset();
      reset();
    }, 200);

    closeModal();
  };

  const { register, handleSubmit, reset, setValue } = useForm({ defaultValues: defaultValues });

  const resizePhoto = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.problem.resize(problem.id, data);
    },
    onSuccess: () => {
      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  const onSubmit = (data: FormValues) => {
    resizePhoto.mutate(data);
  };

  const target = useMemo(() => [process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment], [problem.attachment]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h5 className="modal-title">Redimensionner une photo</h5>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="canvas-size-max">
        {resizePhoto.isError && <Alert variant={resizePhoto.error.variant}>{resizePhoto.error.message}</Alert>}
        {resizePhoto.isSuccess && <Alert variant="success">{resizePhoto.data.message}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ResizePhotoCanvas
            target={target}
            setImageData={(data: `${number};${number};${number};${number}`) => setValue("imageData", data)}
            setRotateData={(data: number) => setValue("imageRotation", data)}
          />

          <div className="mb-3 form-check checkbox">
            <input type="checkbox" className="form-check-input" {...register("addLogo")} id="notifyUser" />
            <label className="form-check-label" htmlFor="notifyUser">
              Ajouter le logo ?
            </label>
          </div>
          <button type="submit" id="submitFormModal" className="d-none"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Annuler
        </button>
        <label htmlFor="submitFormModal" className={"btn btn-primary" + (resizePhoto.isLoading ? " disabled" : "")}>
          Valider {resizePhoto.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </label>
      </Modal.Footer>
    </Modal>
  );
}

export default ResizePhotoModal;
