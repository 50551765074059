import { AdminAPI, ConsoleAPI, MathWeekAPI, MessageAPI, ProblemAPI, ProfilAPI, UserAPI } from "./api";

const useAPI = () => {
  return {
    admin: new AdminAPI(),
    console: new ConsoleAPI(),
    mathWeek: new MathWeekAPI(),
    message: new MessageAPI(),
    problem: new ProblemAPI(),
    profil: new ProfilAPI(),
    user: new UserAPI(),
  };
};

export default useAPI;
