enum ModalTypeEnum {
  AskNewContribution = "askNewContribution",
  ConfirmUser = "confirmUser",
  MathWeekCongrats = "mathWeekCongrats",
  Image = "image",
  Message = "message",
  PhotoProblemProjection = "photoProblemProjection",
  ProblemComments = "problemComments",
  ProblemModify = "problemModify",
  ProblemPublish = "problemPublish",
  ProblemRemove = "problemRemove",
  ProblemReport = "problemReport",
  ResizePhoto = "resizePhoto",
  ValidateResponse = "validateResponse",
  TextProblemProjection = "textProblemProjection",
}

export default ModalTypeEnum;
