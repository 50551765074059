import { faMagnifyingGlassMinus, faMagnifyingGlassPlus, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";

const DEFAULT_ZOOM = 1;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.03;
const DRAW_POS = [40, 40];

type ResizePhotoCanvasProps = {
  target: (File | string)[];
  setImageData: (data: `${number};${number};${number};${number}`) => void;
  setRotateData: (data: number) => void;
};

function ResizePhotoCanvas({ target, setImageData, setRotateData }: Readonly<ResizePhotoCanvasProps>) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);

  const datas = useRef({
    mouseDown: false,
    mousePos: [0, 0],
    MIN_ZOOM: 0.2,
    loaded: false,
    drawPos: DRAW_POS,
    scale: DEFAULT_ZOOM,
    rotateData: 0,
  });

  useEffect(() => {
    const image = imageRef.current;
    const canvas = canvasRef.current;
    const buttons = buttonsRef.current;

    if (!canvas || !image || !buttons) {
      return;
    }

    canvas.style.display = "none";
    Array.from(buttons.children as HTMLCollectionOf<HTMLElement>).forEach((element) => {
      element.style.display = "none";
    });

    window.addEventListener("resize", resizeCanvas, false);
    image.addEventListener("load", loadImage, false);
    canvas.addEventListener("wheel", zoom, false);
    canvas.addEventListener("mousedown", setMouseDown, false);
    canvas.addEventListener("touchstart", setMouseDown, false);
    canvas.addEventListener("mouseup", setMouseUp, false);
    canvas.addEventListener("touchend", setMouseUp, false);
    canvas.addEventListener("mousemove", move, false);
    canvas.addEventListener("touchmove", move, false);

    if (target && target.length > 0) {
      if (typeof target[0] === "string") image.src = target[0];
      else image.src = URL.createObjectURL(target[0]);
    }

    return () => {
      window.removeEventListener("resize", resizeCanvas, false);
      image.removeEventListener("load", loadImage, false);
      canvas.removeEventListener("wheel", zoom, false);
      canvas.removeEventListener("mousedown", setMouseDown, false);
      canvas.removeEventListener("touchstart", setMouseDown, false);
      canvas.removeEventListener("mouseup", setMouseUp, false);
      canvas.removeEventListener("touchend", setMouseUp, false);
      canvas.removeEventListener("mousemove", move, false);
      canvas.removeEventListener("touchmove", move, false);
    };
  }, [target]);

  function loadImage() {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    const buttons = buttonsRef.current;

    if (canvas && buttons && image) {
      canvas.style.display = "block";
      Array.from(buttons.children as HTMLCollectionOf<HTMLElement>).forEach((element) => {
        element.style.display = "block";
      });

      URL.revokeObjectURL(image.src); // free memory
      datas.current.loaded = true;

      resizeCanvas();
    }
  }

  function drawCanvas() {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");
    const canvas = canvasRef.current;

    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (datas.current.loaded) {
      drawImage();
    }

    ctx.fillStyle = "grey";
    ctx.globalAlpha = 0.7;
    ctx.fillRect(0, 0, canvas.width, 40);
    ctx.fillRect(0, canvas.height - 40, canvas.width, canvas.height);
    ctx.fillRect(0, 40, 40, canvas.height - 80);
    ctx.fillRect(canvas.width - 40, 40, 40, canvas.height - 80);

    ctx.fillStyle = "black";
    ctx.globalAlpha = 1;

    ctx.fillRect(40 - 2, 40 - 2, canvas.width - 80 + 4, 2);
    ctx.fillRect(40 - 2, 40 - 2, 2, canvas.height - 80 + 4);
    ctx.fillRect(40 - 2, canvas.height - 40, canvas.width - 80 + 4, 2);
    ctx.fillRect(canvas.width - 40, 40 - 2, 2, canvas.height - 80 + 4);

    ctx.fillRect(0, canvas.height / 3, canvas.width, 1);
    ctx.fillRect(0, (canvas.height / 3) * 2, canvas.width, 1);
    ctx.fillRect(canvas.width / 3, 0, 1, canvas.height);
    ctx.fillRect((canvas.width / 3) * 2, 0, 1, canvas.height);
  }

  // Draw the image
  function drawImage() {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    if (!canvas || !image) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    var w = image.width * datas.current.scale;
    var h = image.height * datas.current.scale;
    var x = datas.current.drawPos[0];
    var y = datas.current.drawPos[1];

    var rotValue;
    switch (datas.current.rotateData) {
      case 0:
        rotValue = [0, 0, 1, 1];
        break;
      case 90:
        rotValue = [0, 1, 1, -1];
        break;
      case 180:
        rotValue = [1, 1, -1, -1];
        break;
      case 270:
        rotValue = [1, 0, -1, 1];
        break;
      default:
        rotValue = [0, 0, 1, 1];
    }

    var xy = rotateAngle(canvas.width / 2, canvas.height / 2, datas.current.drawPos[0], datas.current.drawPos[1], datas.current.rotateData);
    x = xy[0];
    y = xy[1];

    var ab = rotateAngle(canvas.width / 2, canvas.height / 2, 40, 40, datas.current.rotateData);
    var a = ab[0] - rotValue[0] * w;
    var b = ab[1] - rotValue[1] * h;

    if (rotValue[2] * x > rotValue[2] * a) {
      x = a;
    }
    if (rotValue[3] * y > rotValue[3] * b) {
      y = b;
    }

    if (rotValue[2] * (x + image.width * datas.current.scale) < rotValue[2] * (canvas.width - a)) {
      x = canvas.width - a - image.width * datas.current.scale;
    }
    if (rotValue[3] * (y + image.height * datas.current.scale) < rotValue[3] * (canvas.height - b)) {
      y = canvas.height - b - image.height * datas.current.scale;
    }

    datas.current.drawPos = rotateAngle(canvas.width / 2, canvas.height / 2, x, y, -datas.current.rotateData);

    ctx.save();
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((datas.current.rotateData * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    ctx.drawImage(image, x, y, w, h);
    ctx.restore();

    if (datas.current.rotateData === 0 || datas.current.rotateData === 180) {
      setImageData(
        `${Number.parseFloat(((rotValue[3] * (-x + a)) / w).toFixed(4))};${Number.parseFloat(((rotValue[2] * (-y + b)) / h).toFixed(4))};${Number.parseFloat(
          ((canvas.width - 80) / w).toFixed(4)
        )};${Number.parseFloat(((canvas.height - 80) / h).toFixed(4))}`
      );
    } else {
      setImageData(
        `${Number.parseFloat(((rotValue[3] * (-y + b)) / h).toFixed(4))};${Number.parseFloat(((rotValue[2] * (-x + a)) / w).toFixed(4))};${Number.parseFloat(
          ((canvas.width - 80) / h).toFixed(4)
        )};${Number.parseFloat(((canvas.height - 80) / w).toFixed(4))}`
      );
    }
  }

  function rotateAngle(cx: number, cy: number, x: number, y: number, angle: number) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }

  function resizeCanvas() {
    if (!datas.current.loaded) return;

    const canvas = canvasRef.current;

    if (!canvas) return;

    const sizeMax = canvas.closest(".canvas-size-max") || canvas.parentElement?.parentElement;

    if (!sizeMax) return;

    const style = window.getComputedStyle(sizeMax);
    var newWidth = Number.parseInt(style.width) - Number.parseInt(style.paddingLeft) - Number.parseInt(style.paddingRight);
    var newHeight = newWidth * (3 / 4) + 20;

    if (!Number.isInteger(newHeight)) {
      newHeight = Math.round(newHeight);
      newWidth = (newHeight - 20) * (4 / 3);
    }

    canvas.width = newWidth;
    canvas.height = newHeight;

    reComputeScale();

    drawCanvas();
  }

  function zoom(e: WheelEvent) {
    e.deltaY < 0 ? zoomIn() : zoomOut();

    e.preventDefault();
  }

  function zoomIn() {
    if (datas.current.scale < MAX_ZOOM) {
      datas.current.scale += ZOOM_STEP;
      drawCanvas();
    }
  }

  function zoomOut() {
    if (datas.current.scale > datas.current.MIN_ZOOM) {
      datas.current.scale -= ZOOM_STEP;
      drawCanvas();
    }
  }

  function setMouseDown(e: MouseEvent | TouchEvent) {
    datas.current.mouseDown = true;
    if (e instanceof MouseEvent) {
      datas.current.mousePos = [e.x, e.y];
    } else if (e instanceof TouchEvent) {
      datas.current.mousePos = [e.touches[0].clientX, e.touches[0].clientY];
    }
    e.preventDefault();
  }

  function setMouseUp(e: MouseEvent | TouchEvent) {
    datas.current.mouseDown = false;
    e.preventDefault();
  }

  function move(e: MouseEvent | TouchEvent) {
    if (datas.current.mouseDown) {
      var delta = [0, 0];
      if (e instanceof MouseEvent) {
        delta = [e.x - datas.current.mousePos[0], e.y - datas.current.mousePos[1]];
        datas.current.mousePos = [e.x, e.y];
      } else if (e instanceof TouchEvent) {
        delta = [e.touches[0].clientX - datas.current.mousePos[0], e.touches[0].clientY - datas.current.mousePos[1]];
        datas.current.mousePos = [e.touches[0].clientX, e.touches[0].clientY];
      }

      datas.current.drawPos = [datas.current.drawPos[0] + delta[0], datas.current.drawPos[1] + delta[1]];

      drawCanvas();
    }
    e.preventDefault();
  }

  function rotateImage() {
    datas.current.rotateData = (datas.current.rotateData + 90) % 360;
    setRotateData(datas.current.rotateData);

    reComputeScale();

    drawCanvas();
  }

  function reComputeScale() {
    const canvas = canvasRef.current;
    const image = imageRef.current;

    if (!canvas || !image) return;

    if (datas.current.rotateData === 0 || datas.current.rotateData === 180) {
      datas.current.MIN_ZOOM = Math.min((canvas.height - 80) / image.height, (canvas.width - 80) / image.width);
    } else {
      datas.current.MIN_ZOOM = Math.min((canvas.height - 80) / image.width, (canvas.width - 80) / image.height);
    }
    datas.current.scale = datas.current.MIN_ZOOM;
  }

  function reset() {
    datas.current.drawPos = DRAW_POS;

    datas.current.rotateData = 0;
    setRotateData(0);

    reComputeScale();

    drawCanvas();
  }

  return (
    <div className="position-relative text-center m-auto mb-3" style={{ width: "fit-content" }}>
      <img ref={imageRef} className="d-none" src="" alt="" />
      <canvas ref={canvasRef} width="520" height="410" style={{ cursor: "move" }}></canvas>
      <div ref={buttonsRef}>
        <button className="btn btn-sm btn-ternary position-absolute" onClick={rotateImage} style={{ top: 3, right: 5 }} type="button">
          <FontAwesomeIcon icon={faRotate} />
        </button>
        <button className="btn btn-sm btn-ternary position-absolute" onClick={zoomIn} style={{ bottom: 3, left: 5 }} type="button">
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        </button>
        <button className="btn btn-sm btn-ternary position-absolute" onClick={zoomOut} style={{ bottom: 3, left: 40 }} type="button">
          <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
        </button>
        <button className="btn btn-sm btn-ternary position-absolute" onClick={reset} style={{ bottom: 3, right: 5 }} type="button">
          Reset
        </button>
      </div>
    </div>
  );
}

export default ResizePhotoCanvas;
