import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";

class ProfilAPI {
  async activityData() {
    try {
      const response = await getGetAxiosInstance().get("/profil/activity");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async gradeData() {
    try {
      const response = await getGetAxiosInstance().get("/profil/grade");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getNotificationsNumber() {
    try {
      const response = await getGetAxiosInstance().get("/profil/update-notifications");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getNotifications(type: string, page: number) {
    try {
      const response = await getGetAxiosInstance().get("/profil/notifications/" + type + "/" + page);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async subscribeEmails(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/emails-subscribe", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ProfilAPI;
