import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { ApiError, useAPI, useDocumentTitle } from "../../hooks";
import { FormButtonLoading } from "../../components";
import { MutationData } from "../../types";
import { Alert } from "react-bootstrap";

type FormValues = {
    subject: string;
    content: string;
};

const defaultValues: FormValues = {
    subject: "",
    content: "",
};

function AdminMail() {
    const api = useAPI();
    useDocumentTitle("Admin - M@ths en-vie");

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ defaultValues: defaultValues });

    const sendEmail = useMutation<MutationData, ApiError, FormValues>({
        mutationFn: (emailData) => {
            return api.admin.sendEmail(emailData);
        },
        onSuccess: () => {
            reset();
        },
    });

    const onSubmit = (data: FormValues) => {
        sendEmail.mutate(data);
    };

    return (
        <>
            <h5 className="mb-2">Envoyer un courriel</h5>
            {sendEmail.isError && <Alert variant={sendEmail.error.variant}>{sendEmail.error.message}</Alert>}
            {sendEmail.isSuccess && <Alert variant="success">{sendEmail.data.message}</Alert>}

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="mb-3">
                    <label htmlFor="mail-subject" className="form-label">
                        Sujet
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="mail-subject"
                        {...register("subject", {
                            maxLength: { value: 128, message: "Le sujet ne peut pas contenir plus de 128 caractères." },
                        })}
                        placeholder="Sujet"
                        autoFocus
                    />
                    <div className="form-text">128 caractères maximum</div>
                    {errors.subject && <div className="invalid-feedback d-block">{errors.subject.message}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="mail-content" className="form-label">
                        Contenu
                    </label>
                    <textarea
                        className="form-control"
                        id="mail-content"
                        {...register("content", {
                            required: { value: true, message: "Veuillez saisir le contenu du courriel." },
                            maxLength: { value: 1024, message: "Le contenu ne peut pas contenir plus de 1024 caractères." },
                        })}
                        style={{ height: 150 }}
                        placeholder="Rédigez votre courriel"
                        required
                    ></textarea>
                    <div className="form-text">1024 caractères maximum</div>
                    {errors.content && <div className="invalid-feedback d-block">{errors.content.message}</div>}
                </div>
                <p className="fst-italic">* Attention le courriel sera envoyé à TOUS les membres</p>
                <FormButtonLoading label="Envoyer *" type="submit" isLoading={sendEmail.isLoading} className="btn btn-primary" />
            </form>
        </>
    );
}

export default AdminMail;
