import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { ProblemTypeEnum } from "../../Utils";
import { ModalPropsDefault, MutationData, ProblemCommentType, ProblemType } from "../../types";
import { ProblemCommentsKey } from "../../pages/query";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { FormButtonLoading, ProblemComment } from "..";
import { useEffect } from "react";

type ProblemReportModalProps = {
  problem: ProblemType;
  counterRef: React.RefObject<HTMLSpanElement>;
};

type FormValues = {
  comment: string;
};

const defaultValues: FormValues = {
  comment: "",
};

function ProblemCommentsModal({ show, closeModal, saveData, savedData, problem, counterRef }: ModalPropsDefault<FormValues> & ProblemReportModalProps) {
  const api = useAPI();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setTimeout(() => {
      addComment.reset();
    }, 200);
    saveData(getValues());
    closeModal();
  };

  const { isLoading, isError, error, data, refetch } = useQuery<ProblemCommentType[], ApiError>(ProblemCommentsKey(problem.id), () => api.problem.getComments(problem.id), {
    staleTime: 60 * 1000, // 1 min
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    reset(savedData);
  }, [reset, savedData]);

  const addComment = useMutation<MutationData & { comment: ProblemCommentType; commentCounter: number }, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.problem.addComment(problem.id, data);
    },
    onSuccess: (data) => {
      reset();
      queryClient.setQueryData<ProblemCommentType[]>(ProblemCommentsKey(problem.id), (old) => (old ? [data.comment, ...old] : old));
      if (counterRef.current) {
        counterRef.current.textContent = data.commentCounter.toString();
      }
      refetch();
    },
  });

  const onSubmit = (data: FormValues) => {
    addComment.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        {problem.discr === ProblemTypeEnum.TextProblem && <h5 className="modal-title fw-bold">{problem.title}</h5>}
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {problem.discr === ProblemTypeEnum.Photo && (
          <div className="text-center">
            <img className="mb-3 mw-50 h-auto" alt="" src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} />
          </div>
        )}
        {addComment.isError && <Alert variant={addComment.error.variant}>{addComment.error.message}</Alert>}
        {addComment.isSuccess && <Alert variant="success">{addComment.data.message}</Alert>}
        <div className="input-group mb-3"></div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3">
            <textarea
              className="form-control"
              id="comment"
              {...register("comment", {
                required: { value: true, message: "Veuillez saisir votre commentaire." },
                maxLength: { value: 500, message: "Votre commentaire ne peut pas contenir plus de 500 caractères." },
              })}
              placeholder="Rédigez votre commentaire"
            ></textarea>
            {errors.comment && <div className="invalid-feedback d-block">{errors.comment.message}</div>}
          </div>
          <FormButtonLoading type="submit" isLoading={addComment.isLoading} label="Envoyer" className="btn btn-primary" />
        </form>
        <h5 className="mt-3">Commentaires du problème</h5>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert variant={error.variant}>{error.message}</Alert>
        ) : data.length < 1 ? (
          <Alert variant="info">Aucun commentaire</Alert>
        ) : (
          data.map((comment) => <ProblemComment key={comment.id} comment={comment} problemId={problem.id} counterRef={counterRef} />)
        )}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Fermer
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProblemCommentsModal;
