import LinkWithQuery from "./LinkWithQuery";

type PaginationProps = {
    page: number;
    nbrPages: number;
    nbrPagesInARow: number;
    url?: string;
};

function Pagination({ page, nbrPages, nbrPagesInARow, url = "" }: PaginationProps) {
    const scrollToTop = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }), 1);
        e.currentTarget.blur();
    };

    if (page < 1 || page > nbrPages) {
        return;
    }

    // $cptDeb and $cptFin is needed to know which element to render
    let cptDeb, cptFin;

    if (nbrPages <= nbrPagesInARow) {
        cptDeb = 1;
        cptFin = nbrPages;
    } else {
        let cptFin1, cptDeb1;

        cptDeb1 = page - Math.floor(nbrPagesInARow / 2);
        cptFin1 = page + Math.floor(nbrPagesInARow / 2);

        if (cptDeb1 <= 1) {
            cptDeb = 1;
            cptFin = nbrPagesInARow;
        } else if (cptDeb1 > 1 && cptFin1 < nbrPages) {
            cptDeb = cptDeb1;
            cptFin = cptFin1;
        } else {
            cptDeb = nbrPages - nbrPagesInARow + 1;
            cptFin = nbrPages;
        }
    }

    const arrayRange = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

    return (
        <div className="mw-100">
            <nav aria-label="Page navigation">
                <ul className="pagination m-0 flex-wrap">
                    {/* for the previous bar */}
                    {page > 1 ? (
                        <li className="page-item">
                            <LinkWithQuery className="page-link" to={url + (page - 1)} onClick={scrollToTop} rel="prev">
                                &laquo;
                            </LinkWithQuery>
                        </li>
                    ) : (
                        <li className="page-item disabled">
                            <span className="page-link">&laquo;</span>
                        </li>
                    )}

                    {/* if the cptDeb is more than one we need to put the link 1 to retrieve to the beggining */}
                    {cptDeb >= 2 && (
                        <li className="page-item">
                            <LinkWithQuery className="page-link" to={url + 1} onClick={scrollToTop}>
                                1
                            </LinkWithQuery>
                        </li>
                    )}

                    {/* if there is a space between 1 and cptDeb */}
                    {cptDeb >= 3 && (
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    )}

                    {/* for all of the link between cptDeb and cptFin */}
                    {[...arrayRange(cptDeb, cptFin, 1)].map((cpt) =>
                        cpt === page ? (
                            <li key={cpt} className="page-item active" aria-current="page">
                                <span className="page-link">{cpt}</span>
                            </li>
                        ) : (
                            <li key={cpt} className="page-item">
                                <LinkWithQuery className="page-link" to={url + cpt} onClick={scrollToTop}>
                                    {cpt}
                                </LinkWithQuery>
                            </li>
                        )
                    )}
                    {/* same thing from the start */}
                    {cptFin <= nbrPages - 2 && (
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                    )}

                    {cptFin <= nbrPages - 1 && (
                        <li className="page-item">
                            <LinkWithQuery className="page-link" to={url + nbrPages} onClick={scrollToTop}>
                                {nbrPages}
                            </LinkWithQuery>
                        </li>
                    )}

                    {page < nbrPages ? (
                        <li className="page-item">
                            <LinkWithQuery className="page-link" to={url + (page + 1)} onClick={scrollToTop} rel="next">
                                &raquo;
                            </LinkWithQuery>
                        </li>
                    ) : (
                        <li className="page-item disabled">
                            <span className="page-link">&raquo;</span>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default Pagination;
