import { generatePath, useNavigate, useParams } from "react-router-dom";
import * as Routes from "../../routes";
import { useEffect, useState } from "react";
import { ProblemTypeEnum } from "../../Utils";

type ParamsType = {
    type: ProblemTypeEnum;
    page: string;
};

export default function ProtectedEdition({ children }: React.PropsWithChildren) {
    const { type, page } = useParams<ParamsType>() as ParamsType;
    const navigate = useNavigate();

    const [canVisit, setCanVisit] = useState(false);

    useEffect(() => {
        if (!(page && Number.isInteger(Number.parseInt(page)) && Number.parseInt(page) > 0)) {
            navigate(generatePath(Routes.EDITION, { type: type, page: "1" }));
            return;
        }

        const newCanVisit = type === ProblemTypeEnum.TextProblem || type === ProblemTypeEnum.PhotoProblem;

        if (!newCanVisit) {
            navigate(Routes.HOME, { replace: true });
        }

        setCanVisit(newCanVisit);
    }, [navigate, page, type]);

    return canVisit ? children : null;
}
