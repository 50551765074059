import { useNavigate, useParams } from "react-router-dom";
import * as Routes from "../../routes";
import { useEffect, useState } from "react";
import { ProblemTypeEnum } from "../../Utils";

type ParamsType = {
    type: ProblemTypeEnum;
};

export default function ProtectedContribute({ children }: React.PropsWithChildren) {
    const { type } = useParams<ParamsType>() as ParamsType;
    const navigate = useNavigate();

    const [canVisit, setCanVisit] = useState(Object.values(ProblemTypeEnum).includes(type));

    useEffect(() => {
        const newCanVisit = Object.values(ProblemTypeEnum).includes(type);

        if (!newCanVisit) {
            navigate(Routes.HOME, { replace: true });
        }

        setCanVisit(newCanVisit);
    }, [navigate, type]);

    return canVisit ? children : null;
}
