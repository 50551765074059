import { useNavigate, useParams } from "react-router-dom";
import TextProblemForm from "../components/TextProblemForm";
import { useEffect, useState } from "react";
import { ApiError, useAPI, useDocumentTitle } from "../hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Routes from "../routes";
import { ModalTypeEnum, ProblemFormTypeEnum, ProblemTypeEnum } from "../Utils";
import { PhotoForm, PhotoProblemForm } from "../components";
import { useModals } from "../contexts/modals";
import { toast } from "react-toastify";
import { FormErrors, MutationData, ProblemFormValue } from "../types";
import { ProfilActivityKey, ProfilGradeKey } from "./Profil/query";
import { ProblemsTypeKey } from "./query";

type ParamsType = {
    type: ProblemTypeEnum;
};

function Contribute() {
    useDocumentTitle();
    const { type } = useParams<ParamsType>() as ParamsType;
    const [formErrors, setFormErrors] = useState<FormErrors<ProblemFormValue>>([]);
    const api = useAPI();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { openModal } = useModals();

    const addProblem = useMutation<MutationData, ApiError<ProblemFormValue>, ProblemFormValue>({
        mutationFn: (data) => {
            return api.problem.add(type.toString(), data);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(ProblemsTypeKey(type));
            queryClient.invalidateQueries(ProfilActivityKey);
            queryClient.invalidateQueries(ProfilGradeKey);
            openModal(ModalTypeEnum.AskNewContribution, { message: data.message, type: type });
        },
        onError: (data) => {
            const errors = data.formErrors ?? [];
            if (errors.length === 0) {
                errors.push({ name: "root", message: data.message });
            }
            setFormErrors(errors);
        },
    });

    useEffect(() => {
        addProblem.reset();
        // Entering empty array for errors will reset the form
        setFormErrors([]);
    }, [type]);

    const onSubmit = (data: ProblemFormValue) => {
        if ("attachment" in data && data.attachment && data.attachment.length > 0 && data.attachment[0] instanceof File) {
            const reader = new FileReader();
            reader.onload = (e) => {
                data.attachment = [e.target?.result as string];
                addProblem.mutate(data);
            };
            reader.onerror = function () {
                toast.error("Une erreur est apparue lors de l'upload de la photo, veuillez réessayer ou contacter un administrateur.", { toastId: "contribute-problem" });
            };
            reader.readAsDataURL(data.attachment[0]);

            return;
        }

        addProblem.mutate(data);
    };

    const handleCancel = () => {
        navigate(Routes.HOME);
    };

    return (
        <>
            {type === ProblemTypeEnum.TextProblem && (
                <TextProblemForm onFormSubmit={onSubmit} formErrors={formErrors} isLoading={addProblem.isLoading} isSuccess={addProblem.isSuccess} handleCancel={handleCancel} titleLabel="Ajouter" />
            )}
            {type === ProblemTypeEnum.Photo && (
                <PhotoForm
                    onFormSubmit={onSubmit}
                    formErrors={formErrors}
                    isLoading={addProblem.isLoading}
                    isSuccess={addProblem.isSuccess}
                    handleCancel={handleCancel}
                    titleLabel="Ajouter"
                    formType={ProblemFormTypeEnum.Contribute}
                />
            )}
            {type === ProblemTypeEnum.PhotoProblem && (
                <PhotoProblemForm
                    onFormSubmit={onSubmit}
                    formErrors={formErrors}
                    isLoading={addProblem.isLoading}
                    isSuccess={addProblem.isSuccess}
                    handleCancel={handleCancel}
                    titleLabel="Ajouter"
                    formType={ProblemFormTypeEnum.Contribute}
                />
            )}
        </>
    );
}

export default Contribute;
