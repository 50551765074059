import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiError, useAPI, useUtils } from "../../hooks";
import { DefaultErrorsType, ModalPropsDefault, MutationData, TextProblemType } from "../../types";
import { ProblemIdKey, ProblemsTypeKey } from "../../pages/query";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { ResponseStateEnum } from "../../Utils";

type ValidateResponseModalProps = {
  problem: TextProblemType;
};

type FormValues = {
  response: string;
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  response: {
    maxLength: { value: 16, message: "Merci de saisir moins de 16 caractères." },
    required: "Veuillez saisir la réponse",
  },
};

function ValidateResponseModal({ show, closeModal, problem }: ModalPropsDefault & ValidateResponseModalProps) {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { addBoldToQuestion, br, sanitize } = useUtils();

  const handleClose = () => {
    setTimeout(() => {
      validateResponse.reset();
    }, 200);

    closeModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: async () => await defaultValues });

  const defaultValues = new Promise<FormValues>((resolve) => {
    if (problem.response && problem.responseState === ResponseStateEnum.AnsweredByIa) {
      resolve({
        response: problem.response,
      });
    }
  });

  const validateResponse = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.problem.validateResponse(problem.id, data);
    },
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries(ProblemsTypeKey(problem.discr));
      queryClient.invalidateQueries(ProblemIdKey(problem.id));
      setTimeout(() => {
        handleClose();
      }, 2000);
    },
  });

  const onSubmit = (data: FormValues) => {
    validateResponse.mutate(data);
  };

  const bothResponse = problem.response?.split(";") as string[];

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h5 className="modal-title">Valider la réponse du problème</h5>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {problem.responseState === ResponseStateEnum.Conflict && (
          <div>
            <p className="mb-3">
              Réponse proposé par l'administrateur : <span className="fw-bold">{bothResponse[0]}</span>
            </p>
            <p className="mb-3">
              Réponse proposé par l'IA : <span className="fw-bold">{bothResponse[1]}</span>
            </p>
          </div>
        )}
        {problem.responseState === ResponseStateEnum.AnsweredByIa && (
          <p className="mb-3">
            Valider la réponse de l'IA : <span className="fw-bold">{problem.response}</span>
          </p>
        )}
        <div className="text-break mb-3">
          <span dangerouslySetInnerHTML={sanitize(addBoldToQuestion(br(problem.statement)))}></span>
        </div>
        {validateResponse.isError && <Alert variant={validateResponse.error.variant}>{validateResponse.error.message}</Alert>}
        {validateResponse.isSuccess && <Alert variant="success">{validateResponse.data.message}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3 form-floating">
            <input type="text" {...register("response", defaultErrors.response)} id="text_problem_response" className={"form-control" + (errors.response ? " is-invalid" : "")} placeholder="Réponse" />
            <label htmlFor="text_problem_response" className="form-label">
              Réponse
            </label>
            {errors.response && <div className="invalid-feedback d-block">{errors.response.message}</div>}
          </div>
          <button type="submit" id="submitFormModal" className="d-none"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Annuler
        </button>
        <label htmlFor="submitFormModal" className={"btn btn-primary" + (validateResponse.isLoading ? " disabled" : "")}>
          Valider {validateResponse.isLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </label>
      </Modal.Footer>
    </Modal>
  );
}

export default ValidateResponseModal;
