/* eslint-disable jsx-a11y/img-redundant-alt */
import { useUtils } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { ModalPropsDefault, PhotoProblemType } from "../../types";
import { CloseButton, Modal } from "react-bootstrap";

type PhotoProblemProjectionModalProps = {
  problems: PhotoProblemType[];
  type: number;
};

function PhotoProblemProjectionModal({ show, closeModal, problems, type }: ModalPropsDefault & PhotoProblemProjectionModalProps) {
  const { addBoldToQuestion, sanitize } = useUtils();

  return (
    <Modal show={show} onHide={closeModal} fullscreen>
      <Modal.Header>
        <button className="position-absolute end-0 me-5 btn btn-link link-light text-decoration-none" onClick={closeModal}>
          Fermer
        </button>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {type === 1 || problems.length === 1 ? (
          <div style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: 30 }}>
            <div className="text-center" style={{ height: "50vh" }}>
              <img className="mw-100 mh-100 align-middle" src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problems[0].attachment} alt="Photo indisponible..." />
            </div>
            <div style={{ fontSize: 40 }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problems[0].statement))}></div>
          </div>
        ) : (
          <>
            <div className="text-center">
              <button
                className="btn btn-primary mx-auto my-0"
                onClick={() => {
                  const problems = document.getElementsByClassName("problem");
                  const problemDom = document.querySelector("body .problems");
                  if (problemDom) problemDom.prepend(problems[1]);
                }}
                style={{ width: 40 }}
              >
                <FontAwesomeIcon icon={faArrowsLeftRight} />
              </button>
            </div>

            <div className="d-flex problems">
              {problems.slice(-2).map((problem) => (
                <div key={problem.id} className="problem w-50" style={{ border: "1px solid black", borderRadius: 5, padding: 20, margin: 30 }}>
                  <div className="text-center" style={{ height: "50vh" }}>
                    <img className="mw-100 mh-100" src={process.env.REACT_APP_BASE_API_URL + "/photos/" + problem.attachment} alt="Photo indisponible..." />
                  </div>
                  <div style={{ fontSize: 25, marginTop: 25 }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(problem.statement))}></div>
                </div>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default PhotoProblemProjectionModal;
