enum TextProblemTypeEnum {
    rdt = "Recherche du tout",
    rdp = "Recherche d'une partie",
    rdtpr = "Recherche du tout - parties réitérées",
    rvp = "Recherche de la valeur d'une partie",
    rnp = "Recherche du nombre de parties",
    refa = "Recherche de l'état final",
    reia = "Recherche de l'état initial",
    rea = "Recherche de l'évolution",
    refm = "Recherche de l'état final ",
    reim = "Recherche de l'état initial ",
    rem = "Recherche de l'évolution ",
    rpqa = "Recherche de la petite quantité",
    rgqa = "Recherche de la grande quantité",
    re = "Recherche de l'écart",
    rpqm = "Recherche de la petite quantité ",
    rgqm = "Recherche de la grande quantité ",
    rdr = "Recherche du rapport",
    step = "Problème à étapes",
    propor = "Problème de proportionnalité",
    frac = "Problème avec des fractions",
    english = "Problème en anglais",
}

const getOperators = (type: TextProblemTypeEnum) => {
    switch (type) {
        case TextProblemTypeEnum.rdt:
        case TextProblemTypeEnum.rdp:
        case TextProblemTypeEnum.refa:
        case TextProblemTypeEnum.reia:
        case TextProblemTypeEnum.rea:
        case TextProblemTypeEnum.rpqa:
        case TextProblemTypeEnum.rgqa:
        case TextProblemTypeEnum.re:
            return "+ et -";
        case TextProblemTypeEnum.rdtpr:
        case TextProblemTypeEnum.rvp:
        case TextProblemTypeEnum.rnp:
        case TextProblemTypeEnum.refm:
        case TextProblemTypeEnum.reim:
        case TextProblemTypeEnum.rem:
        case TextProblemTypeEnum.rpqm:
        case TextProblemTypeEnum.rgqm:
        case TextProblemTypeEnum.rdr:
            return "x et /";
        default:
            return false;
    }
};

export default TextProblemTypeEnum;

export { getOperators };
