import { useQuery } from "@tanstack/react-query";
import { ApiError, useAPI, useDocumentTitle } from "../../hooks";
import { ProfilActivityData, ProfilActivityKey } from "./query";

function ProfilActivity() {
    useDocumentTitle("Profile - M@ths en-vie");
    const api = useAPI();
    const { data: activityData } = useQuery<ProfilActivityData, ApiError>(ProfilActivityKey, api.profil.activityData, {
        staleTime: 30 * 1000, // 30 sec
    });

    return (
        <>
            <p>
                Nombre de contributions : <span className="fw-bold">{activityData?.numberProblems}</span>
            </p>
            <p>
                Problèmes publiés : <span className="fw-bold">{activityData?.numberTextProblemsPublic}</span>
            </p>
            <p>
                Photos publiées : <span className="fw-bold">{activityData?.numberPhotosPublic}</span>
            </p>
            <p>
                Photo-problèmes publiées : <span className="fw-bold">{activityData?.numberPhotoProblemsPublic}</span>
            </p>
            <p>
                Commentaires postés : <span className="fw-bold">{activityData?.numberProblemComments}</span>
            </p>
            <p>
                Nombre de problèmes et photos en attente de validation : <span className="fw-bold">{activityData?.numberProblemsWaiting}</span>
            </p>
        </>
    );
}

export default ProfilActivity;
