import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";
import { EditionType } from "../../../types";

class ProblemAPI {
  async stats() {
    try {
      const response = await getGetAxiosInstance().get("/stats");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async problemCounter() {
    try {
      const response = await getGetAxiosInstance().get("/problem-counter");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getProblems(type: string, page: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/problems/" + type + "/" + page, data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getEnigmas(page: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/enigmas/" + page, data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getProblem(idProblem: number) {
    try {
      const response = await getGetAxiosInstance().get("/problem/" + idProblem);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async getComments(idProblem: number) {
    try {
      const response = await getGetAxiosInstance().get("/problem/" + idProblem + "/comments");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async addComment(idProblem: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/problem/" + idProblem + "/comment", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async removeComment(idComment: number) {
    try {
      const response = await getPostPutAxiosInstance().delete("/comment/" + idComment + "/remove");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async like(idProblem: number) {
    try {
      const response = await getPostPutAxiosInstance().put("/problem/" + idProblem + "/like");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async add(type: string, data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/problem/" + type + "/add", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async modify(idProblem: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/problem/" + idProblem + "/modify", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async remove(idProblem: number) {
    try {
      const response = await getPostPutAxiosInstance().delete("/problem/" + idProblem + "/remove");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async publish(idProblem: number) {
    try {
      const response = await getPostPutAxiosInstance().put("/problem/" + idProblem + "/publish");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async report(idProblem: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/problem/" + idProblem + "/report", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async resize(idProblem: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/problem/" + idProblem + "/resize", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async pdf(editionType: EditionType, filters: any, problemIds: number[], shuffle: boolean = false) {
    try {
      const response = await getPostPutAxiosInstance().post("/edition/" + editionType.problemType + "/" + editionType.editionType[0] + "/" + editionType.editionType[1] + "/" + +shuffle, {
        filters: filters,
        problemIds: problemIds,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async validateResponse(idProblem: number, data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/problem/" + idProblem + "/validate-response", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ProblemAPI;
